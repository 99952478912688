<template>
  <div class="row row-xs">
    <div class="col-md-8 mg-t-10">
      <div class="card">
        <div class="card-header pd-y-20 d-md-flex align-items-right justify-content-between" style="right: 0">
          <h6 class="mg-b-0">{{$t('extra.activities')}}</h6>
          <ul class="list-inline d-flex mg-t-20 mg-sm-t-10 mg-md-t-0 mg-b-0">
          </ul>
        </div><!-- card-header -->
        <div class="card-body mb-12 pos-relative pd-0">
          <div class="chart-one mt-3">
            <base-table
                :items="items"
                :fields="fields"
                :is-busy="auth.dashboardLoading"
                class="mt-0"
            ></base-table>
          </div><!-- chart-one -->
        </div><!-- card-body -->
      </div><!-- card -->
    </div>
    <div class="col-md-4 mg-t-10">
      <div class="card">
        <div class="card-header pd-t-20 pd-b-0 bd-b-0">
          <h6 class="mg-b-5">Status</h6>
          <p class="tx-12 tx-color-03 mg-b-0">Percentage of completion</p>
        </div><!-- card-header -->
        <div class="card-body pd-20">
          <div class="chart-two mg-b-14">
            <p v-if="auth.dashboardLoading" align="center"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>
            <bar-chart v-else style="height: 28vh; width: 100%;" class="mt-0" :data="retentionData" :option="retentionOptions"></bar-chart>
          </div><!-- chart-two -->
          <div class="row">
            <div class="col-sm" style="margin: -5%;"></div>
            <div class="col-sm mg-t-20 mg-sm-t-0">
              <h4 class="tx-normal tx-rubik tx-spacing--1 mg-b-5">{{retentionData.datasets[0].data[0]}}<small>%</small></h4>
              <p class="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-primary">Details</p>
              <!--                            <div class="tx-12 tx-color-03">Customers who have upgraded the level of your products.</div>-->
            </div><!-- col -->
            <div class="col-sm mg-t-20 mg-sm-t-0">
              <h4 class="tx-normal tx-rubik tx-spacing--1 mg-b-5">{{retentionData.datasets[0].data[1]}}<small>%</small></h4>
              <p class="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-pink">Officers</p>
              <!--                            <div class="tx-12 tx-color-03">Customers who have ended their subscription.</div>-->
            </div><!-- col -->
            <div class="col-sm mg-t-20 mg-sm-t-0">
              <h4 class="tx-normal tx-rubik tx-spacing--1 mg-b-5">{{retentionData.datasets[0].data[2]}}<small>%</small></h4>
              <p class="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-purple">Documents</p>
              <!--                            <div class="tx-12 tx-color-03">Customers who have ended their subscription.</div>-->
            </div><!-- col -->
          </div><!-- row -->
        </div><!-- card-body -->
      </div><!-- card -->
    </div>
  </div>
</template>

<script>
import BarChart from "../chart/BarChart";
import BaseTable from "../table/BaseTable";
import {mapState} from 'vuex'
// import LineChart from "../chart/LineChart";
export default {
  name: "Growth",
  components:{
    BarChart,
    // LineChart,
    BaseTable
  },
  data:()=>{
    return{

      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
        { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
        { key: 'updated', label: 'Updated?', sortable: true, class: 'text-center' },
        { key: 'verified', label: 'Verified?', sortable: true, class: 'text-center' }
      ],
      revenueOptions: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      },


      retentionOptions: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed:{
    ...mapState([
      'auth'
    ]),
    items:{
      get(){
        return []
      },
      set(val){
        this.$store.commit('auth/updateDashboardData', val)
      }
    },
    retentionData:{
      get(){
        return {
          labels: ['Details', 'Officers', 'Documents'],
          datasets: [{
            label: '',
            data:['0',
              '0',
              '0'
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
          }]
        }
      },
      set(val){
        this.$store.commit('auth/updateDashboardData', val)
      }
    },
  }
}
</script>

<style scoped>

</style>