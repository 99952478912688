import apiClient from "./BaseService";

export default {
    callReadDirectorsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("directorRead", payload);
    },
    callCreateDirectorsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("directorCreate", payload);
    },
    callReadDirectorTypesApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("directorIdTypes", payload);
    },
    callReadCompanyTypesApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("companyTypes", payload);
    },
    callReadCompanyIndustriesApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("companyIndustries", payload);
    },
    callReadSingleDirectorApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("directorReadSingle", payload);
    },
    callDirectorInitiateCompleteApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("createCompleteOne", payload);
    },
    callDirectorFinalizeCompleteApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("createCompleteTwo", payload);
    },
    callDirectorCredentialCompleteApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("createCompleteThree", payload);
    },
    callUploadDirectImageApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("uploadDirectorImage", payload);
    },
}