<template>
  <div>
    <b-row >
      <b-col md="6" >
        <base-upload-card
            name="campaignImageOne"
            title="Card Image"
            :status="model.campaignStatus"
            :url="modelMock.campaignImageOne"
            :id="model.campaignId"
            :is-loading="campaign.campaignsLoading"
            :read-data="readData"
            :upload-data="{
                  campaignId:model.campaignId,
                  imageName: 'campaignImageOne',
                  type:'base64',
                  base64:''
                }"
            read-type="campaign/updateCampaigns"
            remove-type="campaign/removeCampaignImage"
            upload-type="campaign/uploadCampaignImage"
        />
      </b-col>
      <b-col md="6">
        <base-upload-card
            name="campaignImageTwo"
            title="Banner Image"
            :status="model.campaignStatus"
            :url="modelMock.campaignImageTwo"
            :id="model.campaignId"
            :is-loading="campaign.campaignsLoading"
            :read-data="readData"
            :upload-data="{
                  campaignId:model.campaignId,
                  imageName: 'campaignImageTwo',
                  type:'base64',
                  base64:''
                }"
            read-type="campaign/updateCampaigns"
            remove-type="campaign/removeCampaignImage"
            upload-type="campaign/uploadCampaignImage"
        />
      </b-col>
    </b-row>
    <br/>
    <b-row>
      <b-col md="6">
        <base-upload-card
            name="campaignImageThree"
            title="Other Image One"
            :status="model.campaignStatus"
            :url="modelMock.campaignImageThree"
            :id="model.campaignId"
            :is-loading="campaign.campaignsLoading"
            :read-data="readData"
            :upload-data="{
                  campaignId:model.campaignId,
                  imageName: 'campaignImageThree',
                  type:'base64',
                  base64:''
                }"
            read-type="campaign/updateCampaigns"
            remove-type="campaign/removeCampaignImage"
            upload-type="campaign/uploadCampaignImage"
        />
      </b-col>
      <b-col md="6">
        <base-upload-card
            name="campaignImageFour"
            title="Other Image Two"
            :status="model.campaignStatus"
            :url="modelMock.campaignImageFour"
            :id="model.campaignId"
            :is-loading="campaign.campaignsLoading"
            :read-data="readData"
            :upload-data="{
                  campaignId:model.campaignId,
                  imageName: 'campaignImageFour',
                  type:'base64',
                  base64:''
                }"
            read-type="campaign/updateCampaigns"
            remove-type="campaign/removeCampaignImage"
            upload-type="campaign/uploadCampaignImage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// You can also register Quill modules in the component
// import Quill from 'quill'
// import someModule from '../yourModulePath/someQuillModule.js'
// Quill.register('modules/someModule', someModule)
import BaseUploadCard from "@/components/card/BaseUploadCard";
// import BaseButton from "@/components/button/BaseButton";
import {mapState} from 'vuex';

export default {
  components:{
    BaseUploadCard,
    // BaseButton
  },
  data () {
    return {
      model:{
        campaignId: '',
        campaignTitle: '',
        campaignTagline: '',
        campaignDescription: '',
        campaignTarget: '',
        campaignTags:'',
        campaignDuration: '',
        campaignImageOne: '',
        campaignImageTwo: '',
        campaignImageThree: '',
        campaignImageFour: '',
        campaignVideo: '',
        campaignWebsite: '',
        campaignStatus: '',
        campaignCreatedAt: '',
        campaignUpdatedAt: ''
      },
      content: '<h2>I am Example</h2>',
      editorOption: {
        // Some Quill options...
      }
    }
  },
  methods: {
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    updateCampaign(){
      this.$store.dispatch('campaign/editCampaign', this.model)
    }
  },
  computed: {
    ...mapState([
      'campaign',
      'auth'
    ]),
    modelMock(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.model = this.campaign.campaigns.data.filter(it => it.campaignId == localStorage.campaignId)[0]
      return this.model
    },
    readData(){
      return {campaignOrgId:localStorage.orgId}
    },
    campaignId() {
      return localStorage.campaignId
    },
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.model = this.campaign.campaigns.data.filter(it => it.campaignId == localStorage.campaignId)[0]
    console.log('this is current quill instance object', this.editor)
  }
}
</script>

