<template>
    <div id="app">
<!--        <loading :active.sync="auth.a"-->
<!--                 :can-cancel="false"-->
<!--                 loader="dots"-->
<!--                 color="#6d0606"-->
<!--                 :is-full-page="true"></loading>-->
        <transition name="fade" mode="out-in">
            <auth-layout v-if="layout === 'auth-layout'"></auth-layout>
            <app-layout v-if="layout === 'default-layout'"></app-layout>
            <account-layout v-if="layout === 'account-layout'"></account-layout>
            <payment-layout v-if="layout === 'payments-layout'"></payment-layout>
            <collection-layout v-if="layout === 'collection-layout'"></collection-layout>
            <on-boarding-layout v-if="layout === 'on-boarding-layout'"></on-boarding-layout>
            <loader-layout v-if="layout === 'loader-layout'"></loader-layout>
            <not-found-layout v-if="layout === '404-layout'"></not-found-layout>
        </transition>
        <v-tour name="myTour" :steps="steps"></v-tour>
        <notifications group="app" />
    </div>
</template>

<script>
    import AppLayout from "./views/template/layouts/AppLayout";
    import AuthLayout from  './views/template/layouts/AuthLayout'
    import AccountLayout from "./views/template/layouts/AccountLayout";
    import PaymentLayout from "./views/template/layouts/PaymentLayout";
    import OnBoardingLayout from "./views/template/layouts/OnBoardingLayout";
    import LoaderLayout from "./views/template/layouts/component/LoaderLayout";
    import CollectionLayout from "./views/template/layouts/CollectionLayout";
    import NotFoundLayout from "./views/template/layouts/component/NotFoundLayout";
    //import getMAC, { isMAC } from 'getmac'
    // import getMAC from 'getmac'
    import {stage} from '@/./app.config.json'
    //import ClientJS from 'clientjs'
    // import './assets/js/fingerprint2.min'

    import {mapState} from 'vuex'
    // Import component
    // import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    const default_layout = "default";

    // let fingerprint = require('./assets/js/fingerprint2.min');

    export default {
        components:{
            AppLayout,
            AuthLayout,
            AccountLayout,
            OnBoardingLayout,
            PaymentLayout,
            LoaderLayout,
            CollectionLayout,
            NotFoundLayout
        },
        data() {
            return {
                finger: '',
                //isLoading: false,
                steps: [
                    {
                        target: '#v-step-0',  // We're using document.querySelector() under the hood
                        header: {
                            title: 'Get Started',
                        },
                        content: `Welcome to your <strong>Dashboard</strong>. Here you can monitor the activity and status of your account !`,
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    },
                    {
                        target: '#v-step-1',
                        header: {
                            title: 'Details',
                        },
                        content: 'Here you can view all the details about your company',
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    },
                    {
                        target: '#officer',
                        header: {
                            title: 'Officers',
                        },
                        content: 'Assign account role. The roles include signatory, Director, Developer or customer service. (Note that Developer and Customer service have limited access to this account)',
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    },
                    {
                        target: '#document',
                        header: {
                            title: 'Documents',
                        },
                        content: 'kindly provide required company documents. Documents provided will be accessed and verified by Rubies team.',
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    },
                    {
                        target: '#createuser',
                        header: {
                            title: 'Create User',
                        },
                        content: 'Creating a user will give access to Initiating transactions (bulk and single), view balance, generate account statement and more.',
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    }
                ]
            }
        },
        computed: {
            ...mapState([
                'auth'
            ]),
            layout() {
                return (this.$route.meta.layout || default_layout) + '-layout';
            },
            source(){
              return this.$route.params.source
            },
            domain(){
              console.log(stage)
              if (stage === 'dev')
                return 'https://dev-profile.qoosquare.com'
              else
                return 'https://profile.qoosquare.com'
            },

        },
        methods:{

        },
        beforeCreate() {
            // this.initDashboard()
            //console.log(getMAC())
            this.$fingerprint.get((components) => {
              const encryptedText = this.CryptoJS.AES.encrypt(JSON.stringify(components), '12345678901234567890123456789012').toString().substring(0,64)
              console.log('encryptedText>>>>>:::::>>>>>>>',encryptedText)
                this.finger = this.$fingerprint.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
                console.log(this.finger)
                localStorage.xrh = this.finger
                this.$store.commit('auth/updateDeviceId', this.finger)
            });

        },
      mounted() {
          let uri = window.location.href.split('?');
          let getVars = {};
          if (uri.length === 2)
          {
            let vars = uri[1].split('&');
            let tmp = '';
            vars.forEach(function(v){
              tmp = v.split('=');
              if(tmp.length === 2)
                getVars[tmp[0]] = tmp[1];
            });
          }
          if (getVars.action === 'logout')
            localStorage.clear()
            // localStorage.removeItem('')
          console.log("got imei: " + MediaDeviceInfo.deviceId)
          // console.log(this.queryParams.data)
          //this.$router.push('/preloader')
      },
      watch:{
        source: function (){
          console.log(this.source)
          localStorage.source = this.source
        },
      }

    }
</script>



<style>
    @import "assets/lib/@fortawesome/fontawesome-free/css/all.min.css";
    @import "assets/lib/ionicons/css/ionicons.min.css";
    @import "assets/lib/jqvmap/jqvmap.min.css";
    @import "assets/css/dashforge.css";
    @import "assets/css/dashforge.dashboard.css";
    @import "assets/css/dashforge.contacts.css";
    @import "assets/lib/typicons.font/typicons.css";
    @import "assets/lib/prismjs/themes/prism-vs.css";
    @import "assets/lib/datatables.net-dt/css/jquery.dataTables.min.css";
    @import "assets/lib/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
    @import "assets/lib/select2/css/select2.min.css";
    @import "assets/css/dashforge.chat.css";
    @import "assets/css/dashforge.mail.css";
    @import "assets/css/dashforge.contacts.css";
    @import "assets/css/dashforge.filemgr.css";
    /*@import "assets/js/dashforge.filemgr.js";*/
</style>

