<template>
    <div class="content-header">
        <div class="row-fluid">
          {{companies[0] == null?auth.userInfo.firstName+' '+auth.userInfo.lastName:companies[0].orgName}}
        </div>

        <nav style="display: flex;">
<!--          <list-icon style="cursor: pointer; margin-right: 10px;" @click="$store.commit('auth/updateScreenView','list')"/>-->
<!--          <grid-icon style="cursor: pointer; margin-right: 10px;" @click="$store.commit('auth/updateScreenView','grid')"/>-->
<!--          <sidebar-icon style="cursor: pointer; margin-right: 10px;" @click="$store.commit('auth/updateScreenView','panel')"/>-->
          <log-out-icon style="cursor: pointer; margin-right: 10px;" @click="logout"/>
<!--            <template>-->
<!--                <div class="locale-changer pt-3 form-inline nav-link">-->
<!--                    <select class="form-control-sm form-group" v-model="$i18n.locale">-->
<!--                        <label>Language: </label>-->
<!--                        <option v-for="(lang, i) in auth.languages" :key="`Lang${i}`" :value="lang">{{ lang }}</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--            </template>-->
<!--            <b-badge @click="$i18n.locale = 'en'" title="English"  variant="danger" style="font-size: 16px; cursor: pointer;">EN</b-badge><span style=" padding-left: 10px;"></span>-->
<!--            <b-badge @click="$i18n.locale = 'fr'"  title="French" variant="primary" style="font-size: 16px; cursor: pointer;">FR</b-badge>-->
            <!--            <a href="#" @click="logout()" class="nav-link"><log-out-icon/></a>-->
<!--            <a href="#" @click="startTour()" class="nav-link"><help-circle-icon></help-circle-icon></a>-->
        </nav>
<!--        <v-tour name="myTour" :steps="steps"></v-tour>-->
      <iframe class="d-none"  id="f" :src="domain"></iframe>
    </div><!-- content-header -->
</template>

<script>
    import {mapState} from 'vuex'
    import {LogOutIcon} from "vue-feather-icons"
    import {stage, app} from "@/app.config.json";
    //import { genUserBaseUrl } from "src/app.config";
    // import LanguagesDropdown from 'vue-languages-dropdown'

    export default {
        name: "Header",
        components:{
            LogOutIcon,
            // GridIcon,
            // ListIcon,
            // SidebarIcon,
            // LanguagesDropdown
        },
        data(){
            return{
                acc:'',
                getDropdownValues:'',
                validateSelection:'',
                model:{
                    account:'01893098999'
                },
                country_iso : "mm",
                accounts:[
                    { id: 1, name: 'Option 1'},
                    { id: 2, name: 'Option 2'}
                ]
            }
        },
        methods:{
            optionSelected: function (data) {
                this.$i18n.locale = data.code
                console.log(data)
            },
            logout(){
              localStorage.clear()
              let iframe = document.getElementById('f');
              let win;
              try {
                win = iframe.contentWindow;
              } catch(e) {
                win = iframe.contentWindow;
              }
              win.postMessage(JSON.stringify({key: 'token', method: "remove", data: {}}), "*");
              window.location = this.domain+'/login/'+app
            },
            startTour(){
                this.$tours['myTour'].start()
            }
        },
        computed:{
            ...mapState([
                'company',
                'officer',
                'auth'
            ]),
            domain(){
              console.log(stage)
              if (stage === 'dev')
                return 'https://dev-profile.qoosquare.com'
              else
                return 'https://profile.qoosquare.com'
            },
            companies(){
              return this.auth.userInfo.companies.data//.filter(it => it.orgId.toString() !== localStorage.orgId.toString())
            },
        },
        mounted() {
            //$('.my-select').selectpicker();
        }
    }
</script>

<style scoped>

</style>