var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('base-upload-card',{attrs:{"name":"campaignImageOne","title":"Card Image","status":_vm.model.campaignStatus,"url":_vm.modelMock.campaignImageOne,"id":_vm.model.campaignId,"is-loading":_vm.campaign.campaignsLoading,"read-data":_vm.readData,"upload-data":{
                campaignId:_vm.model.campaignId,
                imageName: 'campaignImageOne',
                type:'base64',
                base64:''
              },"read-type":"campaign/updateCampaigns","remove-type":"campaign/removeCampaignImage","upload-type":"campaign/uploadCampaignImage"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('base-upload-card',{attrs:{"name":"campaignImageTwo","title":"Banner Image","status":_vm.model.campaignStatus,"url":_vm.modelMock.campaignImageTwo,"id":_vm.model.campaignId,"is-loading":_vm.campaign.campaignsLoading,"read-data":_vm.readData,"upload-data":{
                campaignId:_vm.model.campaignId,
                imageName: 'campaignImageTwo',
                type:'base64',
                base64:''
              },"read-type":"campaign/updateCampaigns","remove-type":"campaign/removeCampaignImage","upload-type":"campaign/uploadCampaignImage"}})],1)],1),_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('base-upload-card',{attrs:{"name":"campaignImageThree","title":"Other Image One","status":_vm.model.campaignStatus,"url":_vm.modelMock.campaignImageThree,"id":_vm.model.campaignId,"is-loading":_vm.campaign.campaignsLoading,"read-data":_vm.readData,"upload-data":{
                campaignId:_vm.model.campaignId,
                imageName: 'campaignImageThree',
                type:'base64',
                base64:''
              },"read-type":"campaign/updateCampaigns","remove-type":"campaign/removeCampaignImage","upload-type":"campaign/uploadCampaignImage"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('base-upload-card',{attrs:{"name":"campaignImageFour","title":"Other Image Two","status":_vm.model.campaignStatus,"url":_vm.modelMock.campaignImageFour,"id":_vm.model.campaignId,"is-loading":_vm.campaign.campaignsLoading,"read-data":_vm.readData,"upload-data":{
                campaignId:_vm.model.campaignId,
                imageName: 'campaignImageFour',
                type:'base64',
                base64:''
              },"read-type":"campaign/updateCampaigns","remove-type":"campaign/removeCampaignImage","upload-type":"campaign/uploadCampaignImage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }