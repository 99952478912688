<template>
    <div class="content-body">
        <div class="container pd-x-0">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item"><a href="#">User</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Director Profile</li>
                  </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">My Director Profile</h4>
                <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
                <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLoginDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
              </div>
              <div class="d-none d-md-block">
                <!--                    <new-account-button></new-account-button>-->
                <!--                    <router-link to="/new-director" class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="plus" class="wd-10 mg-r-5"></i> {{$t('extra.newOfficer')}}</router-link>-->
              </div>
            </div>

            <!--TODO TOP CARDS-->
            <top-cards></top-cards>
            <!--TODO GROWTH-->
            <officer-profile-form></officer-profile-form>


        </div><!-- container -->
    </div>
</template>

<script>
    import TopCards from "../../components/card/TopCards";
    // import DetailsForm from "../../components/form/DetailsForm";
    // import {} from ''
    import OfficerProfileForm from "../../components/form/OfficerProfileForm";
    // import BaseTable from "../../components/table/BaseTable";
    import {mapState} from 'vuex'
    export default {
        name: 'Home',
        components:{
            TopCards,
            OfficerProfileForm,
        },
        data(){
            return{
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'directorFirstName', label: 'First Name', sortable: true, sortDirection: 'desc' },
                    { key: 'directorLastName', label: 'Surname', sortable: true, sortDirection: 'desc' },
                    { key: 'directorDesignation', label: 'Role', sortable: true, class: 'text-center' },
                    { key: 'directorAuthBank', label: 'Bank Verified', sortable: true, class: 'text-center' },
                    { key: 'directorAuthStatus', label: 'DIR/SIG Verified', sortable: true, class: 'text-center' },
                    { key: 'actionx', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        methods:{

        },
        computed:{
            ...mapState([
                'director',
                'auth'
            ]),

            directors:{
                get(){
                    return this.director.directors
                },
                set(val){
                    this.$store.commit('director/updateOfficers', val)
                }
            },
        }
    }
</script>
