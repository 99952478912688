import Campaign from "@/views/campaign/Campaign";
import CampaignDetails from "@/views/campaign/CampaignDetails";

export default [
    {
        path: '/campaigns',
        name: 'Campaign',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Campaign,
    },
    {
        path: '/campaign-details',
        name: 'CampaignDetails',
        meta: {layout: 'on-boarding',authRequired:true},
        component: CampaignDetails,
    },
    {
        path: '/campaign-details',
        name: 'CampaignDetails',
        meta: {layout: 'on-boarding',authRequired:true},
        component: CampaignDetails,
    },
]