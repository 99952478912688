<template>
  <div>
    <b-button @click="showModal = !showModal" variant="secondary"><b-icon-plus/> Add New</b-button>
    <base-table
        :items="projectTracker"
        :fields="fields"
        :show-title="true"
        :is-busy="projection.projectionLoading"
        class="mt-2"
    ></base-table>
    <project-tracker-modal :show="showModal" :row="{}"/>
  </div>
</template>

<script>
import BaseTable from "@/components/table/BaseTable";
import {mapState} from 'vuex'
import ProjectTrackerModal from "@/components/modal/projection/ProjectTrackerModal";
export default {
  name: "ProjectTrackerTable",
  components:{
    ProjectTrackerModal,
    BaseTable
  },
  data(){
    return {
      showModal: false,
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'projectTrackerActivity', label: 'Activity', sortable: true, sortDirection: 'desc', class: 'text-right' },
        { key: 'projectTrackerActivityWeight', label: 'Activity Weight', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'projectTrackerStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'projectTrackerRemark', label: 'Remark', sortable: true, class: 'text-right' },
        { key: 'projectTrackerTargetDate', label: 'Target Date', sortable: true, class: 'text-left' },
        { key: 'projectTrackerCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
        { key: 'actionsProjectTracker', label: 'Action', sortable: true, class: 'text-left' }
      ],
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('projection/updateProjectTracker',{projectTrackerCampaignId: localStorage.campaignId})
    }
  },
  computed:{
    projectTracker:{
      get(){
        return this.projection.projectTracker.data
      },
      set(val){
        this.$store.commit('projection/updateProjectTracker', val)
      }
    },
    ...mapState([
        'auth',
        'projection'
    ])
  },
  mounted() {
    this.loadData();
  },
}
</script>

<style scoped>

</style>