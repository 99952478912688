<template>
    <b-progress v-if="show" :value="value" variant="success" striped :animated="animate"></b-progress>
</template>

<script>
    export default {
        name: "BaseLoader",
        props:['show'],
        data(){
            return{
                animate: true,
                value:0
            }
        },
        computed:{
            showLoader:{
                get(){
                    return this.show
                },
                set(){
                    this.value = 0
                }
            }
        },
        mounted() {
            this.timer = setInterval(() => {
                if (this.value < 100 && this.showLoader)
                    this.value = this.value + 10
            }, 2000)
        }
    }
</script>

<style scoped>

</style>