<template>
  <div class="login">
    <b-row class="qoo-row">
      <b-col md="4">

      </b-col>
      <b-col md="4">
        <h1 class="title"><a href="/"><img class="login-logo" src="@/assets/img/qoosquarefulllogo.png"/></a></h1>
        <div style="display: block; text-align: center;">
          <b-form @submit.prevent="submit"  class="qoo-form">
            <h3 style="text-align: center; padding-bottom: 5%;">Enter Passcode to proceed</h3>
            <b-form-group>
              <the-mask placeholder="Enter Passcode" type="password" required v-model="model.passcode" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
            </b-form-group>

            <b-form-group>
              <base-button title="Submit" :loading="isLoading"/>
            </b-form-group>
          </b-form>
        </div>
      </b-col >
      <b-col md="4">

      </b-col>
    </b-row>
  </div>
</template>

<script>
import BaseButton from "../../components/button/BaseButton";
import {mapState} from 'vuex';

// import bifrostCors from 'bifrost-cors'
// import $ from 'jquery'
export default {
  name: "PasscodeForm",
  props:['formType'],
  components:{
    BaseButton
  },
  data() {
    return {
      model: {
        passcode: '',
        token: ''
      },
    }
  },
  methods:{
    async submit() {
      //localStorage.clear()
      if (this.formType === 'resume') {
        await this.$store.dispatch("resume/updateResume", this.model, {root: false})
      }else{
        await this.$store.dispatch("payslip/updatePayslips", this.model, {root: false})
      }
      this.model.passcode = ''
      //this.model.token = ''
      //this.$route.params
    },
  },
  computed:{
    ...mapState([
      'resume',
      'payslip',
    ]),
    isLoading(){
      if (this.formType === 'resume')
        return this.resume.resumeLoading
      else
        return this.payslip.payslipsLoading
    }
  },
  mounted() {
      this.model.token = this.$route.params.uid
      console.log(this.model.token)
  }
}
</script>

<style scoped>
/*@import "../../assets/style/css/style.css";*/
</style>