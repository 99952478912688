<template>
  <div>
    <span v-if="resume.screen === 'passcode'">
      <passcode-form form-type="resume"/>
    </span>
    <span class="resume-app" v-else>
      <div class="hero-first-section">
        <div class="first-navyBlue-control">
          <div class="row">
            <div class="col-md-5">
              <div class="first-group">
                <div class="dp-control">
                  <img :src="resume.employeeResume.employeeProfileImage" alt="dp" class="dp">
                </div>

                <p class="profile-top-header">Profile</p>

                <div class="underline-first">
                </div>


                <div class="profile-control">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">First Name</p>
                        <p class="profile-first-text">{{ resume.employeeResume.employeeFirstName }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Middle Name</p>
                        <p class="profile-first-text">{{ resume.employeeResume.employeeMiddleName }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Surname</p>
                        <p class="profile-first-text">{{ resume.employeeResume.employeeLastName }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Date Of Birth</p>
                        <p class="profile-first-text">{{ resume.employeeResume.employeeDob }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Gender</p>
                        <p class="profile-first-text">{{ resume.employeeResume.employeeGender }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Phone</p>
                        <p class="profile-first-text">{{ resume.employeeResume.employeePhoneNumber }}</p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="profile-group">
                        <p class="profile-first-header">Email</p>
                        <p class="profile-first-text">{{ resume.employeeResume.employeeEmail.toLowerCase() }}</p>
                      </div>
                    </div>
                  </div>
                </div>

<!--                <h5>Bio</h5>-->
<!--                <p class="bio-text">lorem ipsum dolor sit amet,-->
<!--                  consectetur adipiscing elit, sed do-->
<!--                  eiusmod tempor incididunt ut labore-->
<!--                  et dolore magna aliqua. ut enim ad-->
<!--                  minim veniam, quis nostrud-->
<!--                  exercitation ullamco laboris nisi ut-->
<!--                  aliquip ex ea commodo consequat.-->
<!--                  duis aute irure dolor in lorem ipsum-->
<!--                  dolor sit amet, consectetur adipiscing-->
<!--                  elit, sed do eiusmod tempor-->
<!--                  incididunt ut labore et dolore magna-->
<!--                  aliqua. ut enim ad minim veniam,-->
<!--                  quis nostrud exercitation ullamco</p>-->


                <div class="first-border"></div>

                <div class="employee-group">
                  <p class="employee-header">Employee History</p>
                  <div class="underline-second"></div>
                </div>

                <div v-for="employer in resume.employeeResume.employeeDetails" v-bind:key="employer.employeeDetailId" class="red-container">
                  <div class="red-control">
                    <img :src="employer.employeeDetailEmployerLogo" alt="red-circle" class="red-circle">
                  </div>
                  <div class="red-rubies">
                    <p class="red-rubies-header">{{ employer.employeeDetailEmployerName | titleCase }}</p>
                    <p class="red-rubies-date">{{ employer.employeeDetailStartDate }} - {{ employer.employeeDetailEndDate }}</p>
                  </div>
                </div>

              </div>


            </div>



            <div class="col-md-7">
              <div class="second-group">
                <div class="logo-control">
                  <img src="@/assets/img/drawable-xxhdpi/logo.png" alt="logo" class="logo">
                </div>

                <!--                    <button class="projects-button">Projects</button>-->
                <p class="section-header">Education</p>


                <div v-for="education in resume.employeeResume.educations" v-bind:key="education.educationId" class="orange-container">
                  <div class="orange-control-first">
                    <img :src="education.educationSchoolLogo" alt="orange-circle" class="orange-circle">
                  </div>
                  <div class="orange-rubies-first">
                    <p class="orange-rubies-header">{{ education.educationSchoolName }}</p>
                    <p style="font-weight: lighter; color: #8392a5; font-size: 11px;  margin-bottom: 1px;">
                      <span>{{education.educationDegree}}</span>
                      <span v-if="education.educationDegree">{{', '+education.educationField}}</span>
                      <span v-else>{{education.educationField}}</span>
                      <span v-if="education.educationGrade || education.educationField">{{', '+education.educationGrade}}</span>
                      <span v-else>{{education.educationGrade}}</span>
                    </p>
                    <p style="font-weight: lighter; color: #8392a5; font-size: 11px; margin-bottom: 8px;">
                      <span>{{ education.educationStartYear }} - {{ education.educationEndYear }}</span><br/>
                      <span>Activities/Society: {{ education.educationActivities }}</span><br/>
                      <span>{{ education.educationDescription }}</span>
                    </p>
                  </div>
                </div>

                <!--                    <button class="education-button">Education</button>-->
                <p class="section-header">Projects</p>


                <div v-for="project in resume.employeeResume.projects" v-bind:key="project.projectId" class="orange-stick-container">
                  <div class="orange-stick-control-first">
                    <img src="@/assets/img/drawable-xxhdpi/orange-stick.png" alt="orange-stick" class="orange-stick">
                  </div>

                  <div class="orange-stick-rubies-first">
                    <p class="orange-stick-second-text">{{ project.projectTitle }} </p>
                    <p class="orange-stick-first-text">{{ project.projectDescription }}</p>
                    <p class="orange-stick-date">{{ project.projectCreatedAt }}</p>
                  </div>
                </div>

                <div class="second-border"></div>

                <p class="section-header">Skills</p>

                <div v-for="skill in resume.employeeResume.skills" v-bind:key="skill.skillId" class="orange-stick-container">
                  <div class="orange-stick-control-first">
                    <img src="@/assets/img/drawable-xxhdpi/orange-stick.png" alt="orange-stick" class="orange-stick">
                  </div>
                  <div class="orange-stick-rubies-first">
                    <p class="orange-stick-second-text">{{ skill.skillName | titleCase }}</p>
                    <p class="orange-stick-date">{{ skill.skillCategoryName.toUpperCase() }}<br>
                      Effective Date: {{ skill.skillSkillEffectiveDate }}</p>
                  </div>
                </div>


              </div>

            </div>


          </div>
        </div>
      </div>
      <div class="third-border"></div>
      <div class="second-footer">
        <div class="">
          <div class="logo-footer-control">
            <img src="@/assets/img/drawable-xxhdpi/logo-footer.png" alt="logo-footer" class="logo-footer">
          </div>
          <div class="footer-top">
            <div class="row">
              <div class="col-md-12">
                <div class="after-logo-footer-text">
                  <p class="logo-footer-text-one">For more enquiries, contact - support@qusquares.com</p>
                  <p class="logo-footer-text-two">©Qusquares 2020. All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>

  </div>
</template>

<script>
// import BaseTable from "@/components/table/BaseTable";
import PasscodeForm from "@/components/form/PasscodeForm";
import {mapState} from 'vuex'
export default {
  name: "Resume",
  data(){
    return {

    }
  },
  components:{
    PasscodeForm
  },
  computed:{
    ...mapState([
        'resume'
    ])
  },
  mounted() {
    this.$store.commit('resume/updateScreen', 'passcode')
  }
}
</script>

<style scoped>
  @import "../../assets/style/resume/luminous.css";
  @import "../../assets/style/resume/style.css";
</style>