import ProjectionService from "../../service/ProjectionService";
import swal from "sweetalert";

export const state = {
    screen:'passcode',
    expenseProjections:{
        data: [],
        responseCode: '',
        responseMessage:''
    },
    salesProjections:{
        data: [],
        responseCode: '',
        responseMessage:''
    },
    customerProjections:{
        data: [],
        responseCode: '',
        responseMessage:''
    },
    projectTracker:{
        data: [],
        responseCode: '',
        responseMessage:''
    },
    projectionLoading: false
}
export  const getter = {

}
export  const mutations = {
    updateExpenseProjection:(state, payload) =>{
        state.expenseProjections = payload
    },
    updateSalesProjection:(state, payload) =>{
        state.salesProjections = payload
    },
    updateCustomerProjection:(state, payload) =>{
        state.customerProjections = payload
    },
    updateProjectTracker:(state, payload) =>{
        state.projectTracker = payload
    },
    updateProjectionLoading:(state, payload) =>{
        state.projectionLoading = payload
    },
    updateScreen:(state, payload) =>{
        state.screen = payload
    },
}
export const actions = {
    //TODO EXPENSES
    updateExpenseProjection: ({commit, state}, payload) => {
        if (state.expenseProjections.data.length < 1)
            commit('updateProjectionLoading', true)
        return ProjectionService.callReadExpenseProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateExpenseProjection', responseData)
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    createExpenseProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callCreateExpenseProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    editExpenseProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callUpdateExpenseProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    editStatusExpenseProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callUpdateExpenseProjectionStatusApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },

    //TODO SALES
    updateSalesProjection: ({commit, state}, payload) => {
        if (state.salesProjections.data.length < 1)
            commit('updateProjectionLoading', true)
        return ProjectionService.callReadSalesProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateSalesProjection', responseData)
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    createSalesProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callCreateSalesProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    editSalesProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callUpdateSalesProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    editStatusSalesProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callUpdateSalesProjectionStatusApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },

    //TODO CUSTOMER
    updateCustomerProjection: ({commit, state}, payload) => {
        if (state.customerProjections.data.length < 1)
            commit('updateProjectionLoading', true)
        return ProjectionService.callReadCustomerProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateCustomerProjection', responseData)
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    createCustomerProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callCreateCustomerProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    editCustomerProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callUpdateCustomerProjectionApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    editStatusCustomerProjection: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callUpdateCustomerProjectionStatusApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },

    //TODO PROJECT TRACKER
    updateProjectTracker: ({commit, state}, payload) => {
        if (state.projectTracker.data.length < 1)
            commit('updateProjectionLoading', true)
        return ProjectionService.callReadProjectTrackerApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateProjectTracker', responseData)
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    createProjectTracker: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callCreateProjectTrackerApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    editProjectTracker: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callUpdateProjectTrackerApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    },
    editStatusProjectTracker: ({commit, state}, payload) => {
        commit('updateProjectionLoading', true)
        return ProjectionService.callUpdateStatusProjectTrackerApi(payload)
            .then(response => {
                commit('updateProjectionLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateProjectionLoading", false)
                //alert(error)
                // throw error;
            });
    }

}