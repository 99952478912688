<template>
    <div class="login">
        <b-row class="qoo-row">
            <b-col md="4">

            </b-col>
            <b-col md="4">
                <h1 class="title"><a href="#"><img class="login-logo" src="@/assets/img/qoosquarefulllogo.png"/></a></h1>
                <div style="display: block; text-align: center; margin-top: -20px;">
                    <b-form @submit.prevent="register" v-if="auth.regScreen === 'reg'"  class="qoo-form">
                        <h3 style="text-align: center; padding-bottom: 5%;">Your Information</h3>
                        <b-form-group>
                            <b-input v-model="model.firstName" required placeholder="First Name"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <b-input  v-model="model.lastName" required placeholder="Last Name"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <b-input v-model="model.email" required placeholder="Email" type="email"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <b-input-group>
                                <vue-country-code v-model="model.countryCode" @onSelect="onSelect" :preferredCountries="['vn', 'us', 'gb']">
                                </vue-country-code>
                                <b-input v-model="model.phone" required placeholder="Phone Number" type="number"></b-input>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group>
                          <b-input-group>
                            <the-mask placeholder="Set Password" type="password" required v-model="model.password" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
                            <the-mask placeholder="Re-type Password" type="password" required v-model="model.passwordConfirmation" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
                          </b-input-group>
                          <p style="font-size: 12px; color: #F5720E; text-align: left; margin-bottom: 0; font-style: italic; font-family: 'Rubik', sans-serif;">* Password should be 6 digits</p>
                        </b-form-group>
                        <b-form-group>
                            <base-button title="Register" :loading="auth.authLoading"/>
                        </b-form-group>
                        <p class="align-center">Already a member? <span @click="$router.push('/')" class="span-link">Logon to QooSquare</span></p>
                    </b-form>
                    <b-form @submit.prevent="OTPVerification"  v-if="auth.regScreen === 'otp'"  class="qoo-form">
                        <h3 style="text-align: center; padding-bottom: 5%;">Register</h3>
                        <b-form-group>
                            <b-input v-model="model.otp" maxlength="4" minlength="4"  required placeholder="OTP"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <base-button title="Submit" :loading="auth.authLoading"/>
                        </b-form-group>
                        <p class="align-center">Already a member? <span @click="$router.push('/')" class="span-link">Logon to QooSquare</span></p>
                    </b-form>
                </div>
            </b-col >
            <b-col md="4">

            </b-col>
        </b-row>
    </div>
</template>

<script>
    import BaseButton from "../../components/button/BaseButton";
    import {mapState} from 'vuex'
    export default {
        name: "Register",
        components:{
            BaseButton
        },
        data(){
            return {
              model: {
                email: '',
                firstName: '',
                lastName: '',
                middleName: '',
                pin: '1234',
                password: '',
                passwordConfirmation: '',
                phone: '',
                otp: '',
                username: '',
                uniqueRef: '',
                createAccount: 'YES',
                countryCode: '234',
                countryCodeOriginal: '+234',
                source: 'WEB',
                roles : ['1']
              },
              screen:'stepOne'
            }
        },
        methods:{
            onSelect({name, iso2, dialCode}) {
                this.model.countryCode =  dialCode
                console.log(name, iso2, dialCode);
            },
          async register() {
            if (this.model.firstName && this.model.lastName && this.model.email && this.model.phone) {
              this.model.countryCode = this.model.countryCodeOriginal.replace('+','')
              await this.$store.dispatch("auth/initiateEnrollment", this.model, {root: false});
              this.model.uniqueRef = this.auth.response.uniqueRef;
              this.model.username = this.model.phone;
            }
          },
          async OTPVerification() {
            this.model.dialogs = this.$dialogs;
            await this.$store.dispatch("auth/completeEnrollment", this.model, { root: false })
            //if (this.auth.response.responsecode === '00') this.$router.push('/');
          },
          isNumberKey(evt){
            let charCode = (evt.which) ? evt.which : event.keyCode
            return !(charCode > 31 && (charCode < 48 || charCode > 57));

          },
          toScreenTwo(){
            if (this.model.firstName && this.model.lastName)
              this.screen = 'stepTwo'
          },
          toScreenThree(){
            if (this.model.email && this.model.phone)
              this.screen = 'stepThree'
          },
        },
        mounted() {
          this.$store.commit("auth/updateRegScreen", 'reg')
        },
      computed:{
          ...mapState([
            'company',
            'document',
            'auth',
            'auth',
            'officer',
          ]),
        }
    }
</script>

<style scoped>
@import "../../assets/style/css/style.css";

</style>