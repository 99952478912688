<template>
    <div class="login">
        <b-row class="qoo-row">
            <b-col md="4">

            </b-col>
            <b-col md="4">
                <h1 class="title" style="padding-bottom: 0;"><a href="#"><img class="login-logo"  src="@/assets/img/qoosquarefulllogo.png"/></a></h1>
                <div style="display: block; text-align: center; margin-top: -20px;">
                    <b-form @submit.prevent="createCompany" class="qoo-form">
                        <h3 style="text-align: center; padding-bottom: 5%;">Create Business</h3>
                        <b-form-group>
                            <b-input  v-model="model.orgName" required placeholder="Business Name"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <b-select v-model="model.orgType" required>
                                <b-select-option value="">Select Organisation Type</b-select-option>
                                <option value="Limited Liability">Limited Liability</option>
                                <option value="Public Limited Company (PLC)">Public Limited Company (PLC)</option>
                                <option value="Sole Proprietorship">Sole Proprietorship</option>
                                <option value="Association">Association</option>
                                <option value="Non-profit Organisation">Non-profit Organisation</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Enterprises/Business Names">Enterprises/Business Names</option>
                            </b-select>
                        </b-form-group>
                        <b-form-group>
                            <b-input-group>
                                <country-select class="form-control" :countryName="true" :regionName="true" v-model="model.orgCountry" :country="model.orgCountry" topCountry="NG" />
                                <region-select class="form-control" :regionName="true" :countryName="true" v-model="model.orgState" :country="model.orgCountry" :region="model.orgState" />
                            </b-input-group>
                        </b-form-group>
                        <b-form-group>
                            <b-input  v-model="model.orgStreet" required placeholder="Street" type="text"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <b-input v-model="model.orgWebsite" placeholder="Website (optional)" type="text"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <base-button title="Submit" :loading="auth.authLoading"/>
                        </b-form-group>
                    </b-form>
                </div>
            </b-col >
            <b-col md="4">

            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import BaseButton from "../../components/button/BaseButton";
    export default {
        name: "CreateCompany",
        components:{
            BaseButton
        },
        data(){
            return {
              model: {
                orgName: '',
                orgIncNumber: '',
                accountNumber:'',
                orgIncDate: '',
                orgType:'',
                orgCountry:'',
                orgSector:'',
                orgState:'',
                orgCity:'',
                orgStreet:'',
                orgAddressOne:'',
                orgAddressTwo:'',
                orgTaxIdentificationNumber:'',
                orgCertificateToCommenceBusiness:'',
                orgOtherReferenceNumber:'',
                orgPhone:'',
                orgEmail:'',
                orgWebsite:'',
                orgUserId:'',
                orgCustId:'',
                requestId:'7d4558135efb4fedb524ba1a5127a093',
                countryId:'',
                requestType:'auth',
                subscribe: true
              },
            }
        },
        methods:{
          async createCompany(){
            // this.model.orgIncDate = this.$moment(this.model.orgIncDate).format('YYYY-MM-DD')
            // console.log(this.model.orgIncDate)
            if (this.model.orgName !== ''
                && this.model.orgType !== ''
                && this.model.orgCountry !== ''
                && this.model.orgState !== ''
                && this.model.orgStreet !== ''
            ) {
              this.model.orgCity = this.model.orgState;
              this.model.orgEmail = this.auth.userInfo.email;
              this.model.orgPhone = this.auth.userInfo.phone;
              this.model.orgUserId = this.auth.userInfo.id;
              this.model.firstName = this.auth.userInfo.firstName;
              this.model.lastName = this.auth.userInfo.lastName;
              console.log(this.auth.userInfo)
              await this.$store.dispatch('auth/createCompany', this.model, {root: true})

            }else{
              this.$swal('Error!','Empty field detected. Please fill fields with the (*)','error')
            }
          },
        },
        computed:{
          ...mapState([
            'auth',
            'document',
            'officer',
          ]),
        },
        beforeCreate() {
          this.$store.dispatch("auth/validate", {}, {root: true}).then(validUser => {
            console.log('validUser>>>>>>>>',validUser)
            if (!validUser){
              this.router.push('/')
            }
          })
        },
        mounted() {
          //if ()
        }
    }
</script>

<style scoped>
@import "../../assets/style/css/style.css";
</style>