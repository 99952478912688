import Vue from 'vue';
import App from './App.vue';
import './assets/lib/jquery/jquery.min.js';
import store from './store/store';
import i18n from './i18n'
import './filters'
import JsonExcel from 'vue-json-excel'
import VueSweetalert2 from 'vue-sweetalert2';
import { VueSpinners } from '@saeris/vue-spinners';
import * as Fingerprint2 from 'fingerprintjs2'
import VueGoodWizard from 'vue-good-wizard';
import ToggleButton from 'vue-js-toggle-button'
//import AllIosIcon from 'vue-ionicons/dist/ionicons-ios.js'
import FlagsDropdown from 'flags-dropdown-vue'
import NewAccountButton from "./components/button/NewAccountButton";
import VueTheMask from 'vue-the-mask'
import VueCookies from 'vue-cookies'
import 'v-slim-dialog/dist/v-slim-dialog.css';
import SlimDialog from 'v-slim-dialog';
import vueCountryRegionSelect from 'vue-country-region-select';
import Multiselect from 'vue-multiselect'
import VueClipboard from 'vue-clipboard2'
import Notifications from 'vue-notification'
import VueElementLoading from 'vue-element-loading';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';
const datepickerOptions = {};
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(VueSweetalert2, options);
import router from './router'
import jQuery from 'jquery'
window.jQuery = window.$ = jQuery;
import 'jquery-ui';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-select'
import VuePerfectScrollbar from 'vue2-perfect-scrollbar'
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import BaseButton from "./components/button/BaseButton";
import AuthBaseButton from "./components/button/AuthBaseButton";
import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');
import IdleVue from 'idle-vue'
import VueScreenSize from 'vue-screen-size'
import VueCountryCode from "vue-country-code";
import {app, stage} from "@/app.config.json";
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

import VueHtmlToPaper from 'vue-html-to-paper';

const qrOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, qrOptions);

// or, using the defaults with no stylesheet
//Vue.use(VueHtmlToPaper);

Vue.use(VueQuillEditor, /* { default global options } */)
//////////////////:::::CONSTANTS
const xlsx = require('xlsx');
const eventsHub = new Vue()
const options = {
  confirmButtonColor: '#042893',
  cancelButtonColor: '#ff7674',
};
const moment = require('moment')

//////////////////:::::COMPONENTS
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueSpinners);
Vue.prototype.$fingerprint = Fingerprint2;
Vue.use(VueGoodWizard);
Vue.use(ToggleButton);
//Vue.use(AllIosIcon)
Vue.use(NewAccountButton);
Vue.use(VueTheMask)
Vue.use(require('vue-cookies'))
Vue.use(VueCookies)
Vue.$cookies.config('7d')
Vue.$cookies.set('theme','default');
Vue.$cookies.set('hover-time','1s');
Vue.use(SlimDialog);
Vue.use(vueCountryRegionSelect);
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard)
Vue.use(Notifications)
Vue.component('multiselect', Multiselect);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTour);
Vue.use(IdleVue, {eventEmitter: eventsHub, idleTime: 300000})
Vue.use(VueScreenSize)
Vue.use(VueCountryCode);
Vue.use(xlsx);
Vue.prototype.xlsx = xlsx;

require('moment/locale/en-gb')
Vue.use(require('vue-moment'), {moment})
Vue.prototype.moment = moment
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MMM-YYYY')
  }
});
Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).calendar()//.format('DD-MMM-YYYY')
  }
});

import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)


// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

// const $ = require('jquery');

new Vue({
  router,
  BaseButton,
  AuthBaseButton,
  store,
  i18n,
  VuePerfectScrollbar,
  vue2Dropzone,
  NewAccountButton,
  Multiselect,
  FlagsDropdown,
  VueElementLoading,
  onIdle() {
    if (this.$route.meta.authRequired){
      console.log(router.currentRoute.path);
      let timerInterval;
      this.$swal({
        title: '<p style="font-size: 18px">Session Out!</p>',
        html: '<p style="font-size: 15px">Application will log off in less than 15 seconds. click on <b>OK</b> to cancel</p>',
        timer: 15000,
        timerProgressBar: true,
        onBeforeOpen: () => {
        },
        onClose: () => {
          clearInterval(timerInterval)
        }
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        /* Read more about handling dismissals below */
        console.log(result)
        if (result.value === true || result.dismiss.toString() === 'backdrop') {
          console.log('session reset')
        }else{
          localStorage.clear()
          let iframe = document.getElementById('f');
          let win;
          try {
            win = iframe.contentWindow;
          } catch(e) {
            win = iframe.contentWindow;
          }
          let domain = 'https://profile.qoosquare.com'
          if (stage === 'dev') domain = 'https://dev-profile.qoosquare.com'
          win.postMessage(JSON.stringify({key: 'token', method: "remove", data: {}}), "*");
          // window.location = domain+'/login/'+app
          if (app)
            window.location = domain+'/login/'+app
          else
            window.location = '/'
        }
      })
    }
  },
  onActive() {
    // router.push('/')
  },
  render: h => h(App)
}).$mount('#app');

