import CampaignService from "@/service/CampaignService";
import swal from "sweetalert";
import router from "@/router";

export const state = {
    screen:'passcode',
    campaigns:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    campaignsLoading: false,
    imageCampaignsLoading: false
}
export  const getter = {

}
export  const mutations = {
    updateCampaigns:(state, payload) =>{
        state.campaigns = payload
    },
    uploadImageCampaignsLoading:(state, payload) =>{
        state.imageCampaignsLoading = payload
    },
    updateCampaignsLoading:(state, payload) =>{
        state.campaignsLoading = payload
    },
    updateScreen:(state, payload) =>{
        state.screen = payload
    },
}
export const actions = {
    updateCampaigns: ({commit,state}, payload) => {
        if (state.campaigns.data.length < 1)
            commit('updateCampaignsLoading', true)
        return CampaignService.callReadCampaignsApi(payload)
            .then(response => {
                commit('updateCampaignsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateCampaigns', responseData)
                    commit('updateScreen', 'campaign')
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateCampaignsLoading", false)
                //alert(error)
                // throw error;
            });
    },

    createCampaign: ({commit}, payload) => {
        commit('updateCampaignsLoading', true)
        return CampaignService.callCreateCampaignsApi(payload)
            .then(response => {
                commit('updateCampaignsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success').then(
                        ()=>{router.push('campaign-details')}
                    )
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateCampaignsLoading", false)
                //alert(error)
                // throw error;
            });
    },

    editCampaign: ({commit}, payload) => {
        commit('updateCampaignsLoading', true)
        return CampaignService.callEditCampaignApi(payload)
            .then(response => {
                commit('updateCampaignsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Sucess!', responseData.responseMessage, 'sucess!')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateCampaignsLoading", false)
                //alert(error)
                // throw error;
            });
    },

    uploadCampaignImage: ({commit}, payload) => {
        commit('uploadImageCampaignsLoading', true)
        return CampaignService.callUploadCampaignImageApi(payload)
            .then(response => {
                commit('uploadImageCampaignsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    //swal('Sucess!', responseData.responseMessage, 'sucess!')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("uploadImageCampaignsLoading", false)
                //alert(error)
                // throw error;
            });
    },

    removeCampaignImage: ({commit}, payload) => {
        commit('uploadImageCampaignsLoading', true)
        return CampaignService.callRemoveCampaignImageApi(payload)
            .then(response => {
                commit('uploadImageCampaignsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    //swal('Sucess!', responseData.responseMessage, 'sucess!')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("uploadImageCampaignsLoading", false)
                //alert(error)
                // throw error;
            });
    }
}