import PayslipsService from "@/service/PayslipsService";
// import swal from "sweetalert";

export const state = {
    screen:'passcode',
    employeePayslips:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    payslipsLoading: false
}
export  const getter = {

}
export  const mutations = {
    updatePayslips:(state, payload) =>{
        state.employeePayslips = payload
    },
    updatePayslipsLoading:(state, payload) =>{
        state.payslipsLoading = payload
    },
    updateScreen:(state, payload) =>{
        state.screen = payload
    },
}
export const actions = {
    updatePayslips: ({commit}, payload) => {
        commit('updatePayslipsLoading', true)
        return PayslipsService.callReadSharedPayslipsApi(payload)
            .then(response => {
                commit('updatePayslipsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updatePayslips', responseData)
                    commit('updateScreen', 'payslip')
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updatePayslipsLoading", false)
                //alert(error)
                // throw error;
            });
    }
}