<template>
  <div class="content-body">
    <div class="container pd-x-0">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item"><a href="#">Onboarding</a></li>
              <li class="breadcrumb-item active" aria-current="page">Campaigns</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">Campaigns</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
          <!--          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.lastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
        </div>
        <div class="d-none d-md-block">
<!--          <new-account-button></new-account-button>-->
          <button  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" @click="$refs['campaign-modal'].show()"><plus-icon/>New Campaign</button>
          <button style="background-color: #6d0606; border-color: #6d0606;" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <download-excel
                class   = "lnr-cloud-download"
                style="cursor: pointer;"
                :data   = "campaigns"
                worksheet = "My Worksheet"
                :name    = "$route.name+'.xls'">
              <span> <file-icon class="wd-10 mg-r-5"/> {{$t('extra.generateReport')}}</span>
            </download-excel>
          </button>
        </div>
      </div>

      <!--TODO TOP CARDS-->
      <top-cards></top-cards>
      <!--TODO GROWTH-->
      <div v-if="auth.userInfo.companies.data.length > 0">
        <div class="row">
          <div class="col-md-12 mg-t-10">
            <div class="card ht-100p">
              <div class="card-header d-flex align-items-center justify-content-between">
                <h6 class="mg-b-0">Campaigns <i data-feather="chevrons-right" class="wd-10 mg-r-5"></i></h6>
                <div class="d-flex tx-18">
  <!--                <a href="" class="link-03 lh-0"><i class="icon ion-md-refresh"></i></a>-->
  <!--                <router-link to="/new-campaign" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-add"></i></router-link>-->
                </div>
              </div>
              <base-table
                  :items="campaigns"
                  :fields="fields"
                  :show-title="true"
                  :is-busy="campaign.campaignsLoading"
                  class="mt-2"
              ></base-table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <hr/>
        <p style="text-align: center; margin-top: 5%;"><img src="@/assets/img/empty.svg"></p>
        <p style="text-align: center;">No Business Created. <router-link to="/create-company">Click Here</router-link></p>
      </div>

      <b-modal id="campaign-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="campaign-modal">
        <div class="modal-body">
          <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
             @click="hide()">
            <span aria-hidden="true">&times;</span>
          </a>
          <div>
            <h4>Add new campaign</h4>
            <b-form @submit.prevent="createCampaign" enctype="multipart/form-data">
              <b-form-group label="Title">
                <b-input required v-model="model.campaignTitle"/>
              </b-form-group>
              <b-form-group label="Taglines">
                <b-input required v-model="model.campaignTagline"/>
              </b-form-group>
              <b-form-group label="Category">
                <b-select required v-model="model.campaignTags">
                  <b-select-option  value="GAMING">Gaming</b-select-option>
                  <b-select-option  value="TRAVEL">Travel</b-select-option>
                  <b-select-option  value="SHOPPING/DEALS">Shopping and Deals</b-select-option>
                  <b-select-option  value="FINANCE">Finance</b-select-option>
                  <b-select-option  value="ENTERTAINMENT">Entertainment</b-select-option>
                  <b-select-option  value="UTILITIES">Utilities</b-select-option>
                  <b-select-option  value="HEALTH/FITNESS">Health and Fitness</b-select-option>
                </b-select>
              </b-form-group>
              <b-form-group label="Target">
                <b-input type="number" step="0.01" v-model="model.campaignTarget" />
              </b-form-group>
              <b-form-group label="Duration">
                <b-input type="number" v-model="model.campaignDuration" />
              </b-form-group>
              <base-button title="Submit" :loading="campaign.campaignsLoading"/>
            </b-form>
          </div>
        </div><!-- modal-body -->
      </b-modal>

    </div><!-- container -->
  </div>
</template>

<script>
import TopCards from "../../components/card/TopCards";
import BaseTable from "../../components/table/BaseTable";
import {FileIcon, PlusIcon} from 'vue-feather-icons'
import BaseButton from "@/components/button/BaseButton";
import {mapState} from 'vuex';
import $ from "jquery";

export default {
  name: 'Home',
  components:{
    TopCards,
    BaseTable,
    PlusIcon,
    FileIcon,
    BaseButton
  },
  data(){
    return{
      model:{
        campaignOrgId: '',
        campaignTitle: '',
        campaignTagline: '',
        campaignTarget: '',
        campaignTags:'',
        campaignDuration: ''
      },
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'campaignTitle', label: 'Title', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'campaignTagline', label: 'Tagline', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'campaignTags', label: 'Tags', sortable: true, class: 'text-left' },
        { key: 'campaignDuration', label: 'Duration', sortable: true, class: 'text-left' },
        { key: 'campaignStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'campaignCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
        { key: 'actionCampaign', label: 'Action', sortable: true, class: 'text-left' }
      ],
    }
  },
  methods:{
    hide() {
      this.$refs['campaign-modal'].hide()
    },
    async createCampaign(){
      this.model.campaignOrgId = localStorage.orgId
      await this.$store.dispatch('campaign/createCampaign', this.model)
      await this.$store.dispatch('campaign/updateCampaigns', {campaignOrgId:localStorage.orgId})
      this.hide()
    }
  },
  computed:{
    ...mapState([
      'campaign',
      'auth'
    ]),
    campaigns:{
      get(){
        return this.campaign.campaigns.data
      },
      set(val){
        this.$store.commit('campaign/updateCampaigns', val)
      }
    },
  },
  mounted(){
    this.$store.dispatch('campaign/updateCampaigns', {campaignOrgId:localStorage.orgId})
    const app = $('body')
    app.removeClass('show-aside') //: app.removeClass('show-aside')
  }
}
</script>
