<template>
  <div class="card card-file">
    <b-form enctype="multipart/form-data">
      <b-form-group>
        <div class="dropbox">
          <input multiple type="file"  class="input-file" id="files" name="files" :disabled="disableInput"
                 @change="uploadFile" ref="file">
          <div v-if="orgDocUrl.trim() !== '' && orgDocStatus.toUpperCase() === 'NO'" class="card-file-thumb tx-warning-600">
            <b-icon-document-text class="tx-warning"/>
            <!--                        <i class="far fa-file-alt tx-warning"></i>-->
          </div>
          <div v-if="orgDocUrl.trim() !== '' && orgDocStatus.toUpperCase() === 'PENDING'" class="card-file-thumb tx-warning-600">
            <b-icon-document-text class="tx-warning"/>
            <!--                        <i class="far fa-file-alt tx-warning"></i>-->
          </div>
          <div v-else-if="orgDocUrl.trim() !== '' && orgDocStatus.toUpperCase() === 'YES'" class="card-file-thumb tx-success-600">
            <b-icon-document-text class="tx-success"/>
            <!--                        <i class="far fa-file-alt tx-success"></i>-->
          </div>
          <div v-else-if="orgDocUrl.trim() !== '' && orgDocStatus.toUpperCase() === 'REMOVED'" class="card-file-thumb tx-danger-600">
            <b-icon-document-text class="tx-danger"/>
            <!--                        <i class="far fa-file-alt tx-danger"></i>-->
          </div>
          <div v-else class="card-file-thumb tx-gray-600">
            <b-icon-document-text class="tx-gray"/>
            <!--                        <i class="far fa-file-alt"></i>-->
          </div>
        </div>
      </b-form-group>
    </b-form>
    <base-loader style="margin-top: -16px; margin-bottom: 10px;" :show="showLoader"></base-loader>
    <div class="card-body" style="margin-top: -20px;">
      <h6 :title="orgDocName"><a href="#" class="link-02">{{orgDocName}}</a></h6>
      <p>Verified: {{orgDocStatus}}</p>
      <span v-if="orgDocUrl.trim() === ''">
                <p><span style="float: left;">No File Uploaded</span></p>
                <p title="download file template" v-if="orgDocName.trim() === 'Board Resolution'" >
                    <span style="float: right; cursor: pointer;"><a href="https://cooperateacc.s3.amazonaws.com/Board_Resolution_template.docx" target="_blank">
                        <b-icon-download class="tx-primary"></b-icon-download></a>
                    </span>
                </p>
                <p title="download file template" v-if="orgDocName.trim() === 'Reference Form One' || orgDocName.trim() === 'Reference Form Two'" >
                    <span style="float: right; cursor: pointer;"><a href="https://cooperateacc.s3.amazonaws.com/Rubies_Reference_Form_default_blank.pdf" target="_blank">
                        <b-icon-download class="tx-primary"></b-icon-download></a>
                    </span>
                </p>

            </span>
      <span v-else>
                <p title="download" ><span style="float: left; cursor: pointer;"><a :href="orgDocUrl"><b-icon-download class="tx-success"></b-icon-download></a></span></p>
                <p title="delete" v-if="orgDocStatus.toUpperCase() === 'NO'||orgDocStatus.toUpperCase() === 'PENDING'" ><span style="float: right; cursor: pointer;" @click="removeDocuments()"><b-icon-trash class="tx-danger" ></b-icon-trash></span></p>
            </span>
    </div>
  </div>
</template>


<script>
import BaseLoader from "../loader/BaseLoader";
export default {
  name: "UploadCard",
  props:['orgDocName','orgDocStatus','orgDocUrl','orgDocId'],
  components:{
    BaseLoader
  },
  data(){
    return{
      showLoader: false,
      base64:''
    }
  },
  methods:{
    uploadFile(){
      let input = this.$refs.file;
      // console.log(input);
      let files = input.files;
      let size = files[0].size/1000000
      //console.log(size);
      if (size > 1000) {
        const options = {title: 'Error!', size: 'sm'};
        this.$dialogs.alert('File size must not be more than 5mb, please try with a reduced file size', options)
            .then(res => {
              console.log(res) // {ok: true|false|undefined}
            });
        return
      }
      const reader = new FileReader;
      try {
        reader.onload = e => {
          this.base64 = e.target.result;
          // console.log(e.target.result);
          this.createDocuments()
        };
        reader.readAsDataURL(files[0]);
        this.$emit('input', files[0]);
      } catch (e) {
        console.warn(e.message);
      }
    },

    async createDocuments(){
      this.showLoader = true
      await this.$store.dispatch("document/uploadDocuments", {
        base64: this.base64,
        orgDocId: this.orgDocId,
        uploadType: "BASE64",
        orgDocName:this.orgDocName
      }, {root: true})
      await this.$store.dispatch("document/updateDocuments", {
        orgId: localStorage.orgId,
        accID: localStorage.accID,
        readAll: 'NO',
        requestType: 'document'
      }, {root: true})
      this.showLoader = false
    },

    async removeDocuments(){
      this.showLoader = true
      await this.$store.dispatch("document/updateDocumentStatus", {
        orgDocId: this.orgDocId,
        reason: '',
        status: 'REMOVED',
      }, {root: true})
      await this.$store.dispatch("document/updateDocuments", {
        orgId: localStorage.orgId,
        accID: localStorage.accID,
        readAll: 'NO',
        requestType: 'document'
      }, {root: true})
      this.showLoader = false
    },

    async readDocuments(){
      await this.$store.dispatch("document/updateDocuments", {
        orgId: localStorage.orgId,
        accID: localStorage.accID,
        readAll: "NO",
        requestType: 'document'
      }, {root: true})
    },

  },
  computed:{
    disableInput:{
      get(){
        return this.orgDocUrl.trim() !== ''
      },
      set(){

      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
.h-100 {
  height: 100vh !important;
}
@media only screen and  (max-width: 991px) {
  .extra{
    display: none;
  }
}
.image-input{
  display: block;
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder{
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica,serif;
}

.placeholder:hover{
  background: #E0E0E0
}

.file-input{
  display: none;
}

.dropbox {
  z-index: 0;
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  /*padding: 10px 10px;*/
  /*min-height: 200px; !* minimum height *!*/
  position: relative;
  cursor: pointer;
}

.input-file {
  z-index: 0;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  /*padding: 50px 0;*/
}

</style>