<template>
  <div class="row row-xs">
    <div class="col-sm-6 col-lg-3">
      <div :class="['card',  'card-body', 'bg-success']">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Details</h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{'0'}}%</h3>
          <!--                    <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium tx-success">1.2% <i class="icon ion-md-arrow-up"></i></span></p>-->
        </div>
        <div class="chart-three">
          <!--                            <div id="flotChart3" class="flot-chart ht-30"></div>-->
          <!--                    <flot-chart type="flotChart3"></flot-chart>-->
        </div><!-- chart-three -->
      </div>
    </div><!-- col -->
    <div class="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
      <div :class="['card',  'card-body', 'bg-secondary']">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Officers</h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{'0'}}%</h3>
          <!--                    <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium tx-danger">0.7% <i class="icon ion-md-arrow-down"></i></span></p>-->
        </div>
        <div class="chart-three">
          <!--                            <div id="flotChart4" class="flot-chart ht-30"></div>-->
          <!--                    <flot-chart type="flotChart4"></flot-chart>-->
        </div><!-- chart-three -->
      </div>
    </div><!-- col -->
    <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
      <div :class="['card',  'card-body', 'bg-warning']">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Documents</h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{'0'}}%</h3>
          <!--                    <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium tx-danger">0.3% <i class="icon ion-md-arrow-down"></i></span></p>-->
        </div>
        <div class="chart-three">
          <!--                            <div id="flotChart5" class="flot-chart ht-30"></div>-->
          <!--                    <flot-chart type="flotChart5"></flot-chart>-->
        </div><!-- chart-three -->
      </div>
    </div><!-- col -->
    <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
      <div :class="['card',  'card-body', 'bg-info']">
        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Total Officers</h6>
        <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{{0}}</h3>
          <!--                    <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium tx-success">2.1% <i class="icon ion-md-arrow-up"></i></span></p>-->
        </div>
        <div class="chart-three">
          <!--                            <div id="flotChart6" class="flot-chart ht-30"></div>-->
          <!--                    <flot-chart type="flotChart6"></flot-chart>-->
        </div><!-- chart-three -->
      </div>
    </div>
  </div>
</template>

<script>
// import FlotChart from "../FlotChart";
import {mapState} from 'vuex'
export default {
  name: "TopCards",
  components:{

  },
  computed:{
    ...mapState([
      'auth',
      'officer'
    ])
  }
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
</style>