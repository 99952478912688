<template>
  <b-modal id="project-tracker-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="project-tracker-modal">
    <div class="modal-body">
      <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
         @click="hide()">
        <span aria-hidden="true">&times;</span>
      </a>
      <div>
        <h4 v-if="model.type === 'edit'">Edit Project Tracker</h4>
        <h4 v-else>Add New Project Tracker</h4>
        <b-form @submit.prevent="submit" enctype="multipart/form-data">
          <b-form-group label="Activity">
            <b-input placeholder="Activity" type="text" v-model="model.projectTrackerActivity"/>
          </b-form-group>
          <b-form-group label="Activity Weight">
            <b-input placeholder="Activity Weight" type="number" step="0.01" v-model="model.projectTrackerActivityWeight"/>
          </b-form-group>
          <b-form-group label="Evidence">
            <b-input placeholder="Evidence" type="text" v-model="model.projectTrackerEvidence"/>
          </b-form-group>
          <b-form-group label="Remark">
            <b-input placeholder="Remark" type="text" v-model="model.projectTrackerRemark"/>
          </b-form-group>
          <b-form-group label="Projection Month">
            <b-input-group>
              <b-select v-model="model.ProjectTrackerYear">
                <b-select-option v-for="i in 50" v-bind:key="i" :value="currentYear()+i-1">{{currentYear()+i-1}}</b-select-option>
              </b-select>
              <b-select v-model="model.ProjectTrackerMonth">
                <b-select-option v-for="month in months" v-bind:key="month.id" :value="month.id">{{month.name}}</b-select-option>
              </b-select>
            </b-input-group>
          </b-form-group>
          <b-form-group v-if="model.type === 'edit'" label="Status">
            <b-select required v-model="model.projectTrackerStatus">
              <b-select-option value="OPEN">OPEN</b-select-option>
              <b-select-option value="CLOSED">CLOSED</b-select-option>
            </b-select>
          </b-form-group>
          <base-button title="Submit" :loading="projection.projectionLoading"/>
        </b-form>
      </div>
    </div><!-- modal-body -->
  </b-modal>
</template>
<script>
import { mapState } from "vuex"
import BaseButton from "@/components/button/BaseButton";
export default {
  props:['show','row'],
  components:{BaseButton},
  name: 'ProjectTrackerModal',
  data(){
    return{
      password: '',
      model:{
        projectTrackerId:"",
        projectTrackerBusinessId:"",
        projectTrackerCampaignId:"",
        projectTrackerActivity:"",
        projectTrackerActivityWeight:"",
        projectTrackerEvidence:"",
        ProjectTrackerMonth:"",
        ProjectTrackerYear:"",
        projectTrackerRemark:"",
        projectTrackerStatus:"",
        projectTrackerTargetDate:""
      },
      months:[
        {
          id:'01',
          name:'January'
        },
        {
          id:'02',
          name:'February'
        },
        {
          id:'03',
          name:'March'
        },
        {
          id:'04',
          name:'April'
        },
        {
          id:'05',
          name:'May'
        },
        {
          id:'06',
          name:'June'
        },
        {
          id:'07',
          name:'July'
        },
        {
          id:'08',
          name:'August'
        },
        {
          id:'09',
          name:'September'
        },
        {
          id:'10',
          name:'October'
        },
        {
          id:'11',
          name:'November'
        },
        {
          id:'12',
          name:'December'
        }
      ]
    }
  },
  methods:{
    hide() {
      this.$refs['project-tracker-modal'].hide()
    },
    async submit(){
      this.model.projectTrackerCampaignId = localStorage.campaignId
      this.model.projectTrackerBusinessId = localStorage.orgId
      this.model.projectTrackerTargetDate = `${this.model.ProjectTrackerYear}-${this.model.ProjectTrackerMonth}`
      if (this.model.type === 'edit')
        await this.$store.dispatch("projection/editProjectTracker", this.model, {root: false});
      else {
        await this.$store.dispatch("projection/createProjectTracker", this.model, {root: false});
      }
      this.hide();
      this.loadData()
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });

    },
    gradeCode(){
      return  localStorage._gradeCode
    },
    loadData(){
      this.$store.dispatch("projection/updateProjectTracker", {
        readAll:'NO',
        projectTrackerCampaignId: localStorage.campaignId,
        projectTrackerBusinessId: localStorage.orgId
      }, {root: false}).then()
    },
    hideauth: function () {
      this.password = '';
      this.$refs['project-tracker-modal'].hide();
    },
    currentDate(){
      let date = new Date()
      date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
      console.log(date)
      return date
    },
    currentYear(){
      return new Date().getFullYear()
    }
  },
  mounted() {
  },
  computed:{
    ...mapState(['grade','auth','projection']),
  },
  watch: {
    show: function () {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.row).forEach(function(key,index) {
        self.model[key] = self.row[key];
      });
      this.model.ProjectTrackerYear = this.model.projectTrackerTargetDate.split('-')[0]
      this.model.ProjectTrackerMonth = this.model.projectTrackerTargetDate.split('-')[1]
      this.$refs['project-tracker-modal'].show()
    }
  },
}
</script>
