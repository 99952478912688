<template>
  <div class="content-body">
    <div class="container pd-x-0">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item"><a href="#">Onboarding</a></li>
              <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">{{$t('sidebar.dashboard')}}</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
<!--          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.lastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
        </div>
        <div class="d-none d-md-block">
          <!--<new-account-button></new-account-button>-->
            <router-link to="/create-company" v-if="auth.userInfo.companies.data.length <= 0" style="background-color: #ea6b03; border-color: #EA6B03;"  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
              <plus-square-icon/> Create Business</router-link>
            <router-link to="/new-director" class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><plus-icon class="wd-10 mg-r-5"/>Director's Details</router-link>
            <button style="background-color: #6d0606; border-color: #6d0606;" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <download-excel
                class   = "lnr-cloud-download"
                style="cursor: pointer;"
                :data   = "[]"
                worksheet = "My Worksheet"
                :name    = "$route.name+'.xls'">
              <span> <file-icon class="wd-10 mg-r-5"/> {{$t('extra.generateReport')}}</span>
            </download-excel>
          </button>
        </div>
      </div>

      <!--TODO TOP CARDS-->
      <top-cards></top-cards>
      <!--TODO GROWTH-->
      <stats></stats>
      <!--TODO REVENUE-->
      <!--            <revenue></revenue>-->
      <!--TODO HISTORY-->
      <!--            <history></history>-->

    </div><!-- container -->
  </div>
</template>

<script>
// @ is an alias to /src
import TopCards from "../../components/card/TopCards";
// import Growth from "../../components/card/Growth";
import Stats from "../../components/card/Stats";
// import Revenue from "../../components/card/Revenue";
// import History from "../../components/card/History";
import {FileIcon, PlusIcon, PlusSquareIcon} from "vue-feather-icons"
// import NewAccountButton from "../../components/button/NewAccountButton";
import {mapState} from 'vuex'
import $ from "jquery";
export default {
  name: 'DashboardMain',
  components:{
    TopCards,
    Stats,
    FileIcon,
    PlusIcon,
    PlusSquareIcon,
    // Revenue,
    // History
  },
  data(){
    return{

    }
  },
  methods:{
    directorFilteredByUserId(_item) {
      return this.director.directors.filter(function (el) {
        return el.directorUserId == _item;
      });
    },
    async initDashboard(){
      //this.$store.dispatch("company/getSubsidiaries", {}, {root: true});
      //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>calling')
    }
  },
  mounted() {
    //this.initDashboard()
    const app = $('body')
    app.removeClass('show-aside') //: app.removeClass('show-aside')
  },
  computed:{
    ...mapState([
      'auth'
    ])
  }
}
</script>
