import Director from "@/views/director/Director";
import NewDirector from "@/views/director/NewDirector";
import DirectorDetails from "@/views/director/DirectorDetails";
import PersonalProfile from "@/views/user/PersonalProfile";
import DirectorProfile from "@/views/user/DirectorProfile";
import DirectorRegistration from "@/views/director/DirectorRegistration";

export default [
    {
        path: '/directors',
        name: 'Director',
        meta: {layout: 'on-boarding',authRequired:true},
        component: Director,
    },
    {
        path: '/new-director',
        name: 'NewDirector',
        meta: {layout: 'on-boarding',authRequired:true},
        component: NewDirector,
    },
    {
        path: '/director-details',
        name: 'DirectorDetails',
        meta: {layout: 'on-boarding',authRequired:true},
        component: DirectorDetails,
    },
    {
        path: '/personal-profile',
        name: 'PersonalProfile',
        meta: {layout: 'on-boarding',authRequired:true},
        component: PersonalProfile,
    },
    {
        path: '/director-profile',
        name: 'DirectorDetails',
        meta: {layout: 'on-boarding',authRequired:true},
        component: DirectorProfile,
    },
    {
        path: '/director-registration/:directorId',
        name: 'DirectorRegistration',
        meta: {layout: 'auth',authRequired:false},
        component: DirectorRegistration,
    },
]