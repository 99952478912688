
import Resume from "@/views/employee/Resume";

export default [
    {
        path: '/resume/:uid',
        name: 'Resume',
        meta: {layout: 'auth',authRequired:false},
        component: Resume,
    },
]