<template>
    <div class="login">
      <template>
        <view class="container">
        </view>
      </template>
        <b-row class="qoo-row">
            <b-col md="4">

            </b-col>
            <b-col md="4">
                <h1 class="title"><a href="#"><img class="login-logo" src="@/assets/img/qoosquarefulllogo.png"/></a></h1>
                <div style="display: block; text-align: center;">
                    <b-form @submit.prevent="login"  class="qoo-form">
                        <h3 style="text-align: center; padding-bottom: 5%;">Login</h3>
                        <b-form-group>
                            <b-input v-model="model.username" placeholder="Username"></b-input>
                        </b-form-group>
                        <b-form-group>
                          <the-mask placeholder="Password" type="password" required v-model="model.password" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
                          <p class="forgot-link" @click="$router.push('/forgot-password')">Forgot Password?</p>
                        </b-form-group>
                        <b-form-group>
                            <base-button title="Login" :loading="auth.authLoading"/>
                        </b-form-group>
                        <p class="align-center">Not a member? <span @click="$router.push('/register')" class="span-link">Join QooSquare</span></p>
                    </b-form>
                </div>
            </b-col >
            <b-col md="4">

            </b-col>
        </b-row>
    </div>
</template>

<script>
import BaseButton from "../../components/button/BaseButton";
import {mapState} from 'vuex';
// import bifrostCors from 'bifrost-cors'
    // import $ from 'jquery'
    export default {
        name: "Logon.vue",
        components:{
            BaseButton,
        },
        data() {
          return {
            accelerometerData: {},
            model: {
              username: '',
              password: '',
              pin: '1234',
              token: '',
              createAccount: 'YES',
              uniqueRef: '',
              countryCode: '234',
              passwordConfirmation: '',
              mobiledeviceid: '',
              source: 'WEB',
              subscribe: true
            },
          }
        },
        methods:{
          loadApp(){
            this.$store.dispatch("auth/validate", {}, {root: true}).then(validUser => {
              console.log("localStorage.source2222>>>>>>>"+localStorage.source)
              console.log('validUser>>>>>>>>',validUser)
              if (validUser){
                this.$store.dispatch('auth/readCompany', {
                  userId: this.auth.userInfo.id,
                  requestType: 'company'
                }).then(()=> {
                    if (localStorage.orgId == null && this.auth.userInfo.companies.data[0] != null) {
                      localStorage.orgId = this.auth.userInfo.companies.data[0].orgId
                    }
                    console.log("localStorage.source>>>>>>>"+localStorage.source)
                    console.log("localStorage.source>>>>>>>",JSON.stringify({source: localStorage.source, deviceId: localStorage.token}))
                    this.$store.dispatch('auth/navigate', {source: localStorage.source, deviceId: localStorage.token})
                })
              }
            })
          },

          async login() {
            //localStorage.clear()
            localStorage.removeItem('token')
            //this.$store.commit('auth/updateLoginRequest',this.model)
            this.model.mobiledeviceid = this.generateUUID()
            console.log(this.model.mobiledeviceid)
            await this.$store.dispatch("auth/logon", this.model, { root: false })
            this.model = {
              username: '',
              password: '',
              pin: '1234',
              token: '',
              createAccount: 'YES',
              uniqueRef: '',
              countryCode: '234',
              passwordConfirmation: '',
              source: 'WEB',
              subscribe: true
            }
            this.$route.params
          },
          generateUUID() { // Public Domain/MIT
            let d = new Date().getTime();
            if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
              d += performance.now(); //use high-precision timer if available
            }
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
              let r = (d + Math.random() * 16) % 16 | 0;
              d = Math.floor(d / 16);
              return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            }).toUpperCase();
          },
        },
        computed:{
          ...mapState([
            'auth',
          ]),

        },
        mounted() {
          this.loadApp()
        }
    }
</script>

<style scoped>
  @import "../../assets/style/css/style.css";
</style>