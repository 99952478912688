<template>
  <div class="content-body">
    <div class="container pd-x-0">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item"><a href="#">Onboarding</a></li>
              <li class="breadcrumb-item active" aria-current="page">Directors</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">Directors</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
          <!--          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.lastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
        </div>
        <div class="d-none d-md-block">
<!--          <new-account-button></new-account-button>-->
          <router-link to="/new-director" class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><plus-icon class="wd-10 mg-r-5"/>Director's Details</router-link>
          <button style="background-color: #6d0606; border-color: #6d0606;" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <download-excel
                class   = "lnr-cloud-download"
                style="cursor: pointer;"
                :data   = "directors"
                worksheet = "My Worksheet"
                :name    = "$route.name+'.xls'">
              <span> <file-icon class="wd-10 mg-r-5"/> {{$t('extra.generateReport')}}</span>
            </download-excel>
          </button>
        </div>
      </div>

      <!--TODO TOP CARDS-->
      <top-cards></top-cards>
      <!--TODO GROWTH-->
      <div v-if="auth.userInfo.companies.data.length > 0">
        <div class="row">
          <div class="col-md-12 mg-t-10">
            <div class="card ht-100p">
              <div class="card-header d-flex align-items-center justify-content-between">
                <h6 class="mg-b-0">Directors <i data-feather="chevrons-right" class="wd-10 mg-r-5"></i></h6>
                <div class="d-flex tx-18">
  <!--                <a href="" class="link-03 lh-0"><i class="icon ion-md-refresh"></i></a>-->
  <!--                <router-link to="/new-director" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-add"></i></router-link>-->
                </div>
              </div>
              <base-table
                  :items="directors"
                  :fields="fields"
                  :show-title="true"
                  :is-busy="director.directorsLoading"
                  class="mt-2"
              ></base-table>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <hr/>
        <p style="text-align: center; margin-top: 5%;"><img src="@/assets/img/empty.svg"></p>
        <p style="text-align: center;">No Business Created. <router-link to="/create-company">Click Here</router-link></p>
      </div>


    </div><!-- container -->
  </div>
</template>

<script>
import TopCards from "../../components/card/TopCards";
import BaseTable from "../../components/table/BaseTable";
import {FileIcon, PlusIcon} from 'vue-feather-icons'
// import NewAccountButton from "../../components/button/NewAccountButton";
import {mapState} from 'vuex';
import $ from "jquery";

export default {
  name: 'Home',
  components:{
    TopCards,
    BaseTable,
    PlusIcon,
    FileIcon,
    // NewAccountButton
  },
  data(){
    return{
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'directorFirstname', label: 'First Name', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'directorLastname', label: 'Last Name', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'directorDesignation', label: 'Role', sortable: true, class: 'text-left' },
        { key: 'directorStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'directorCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
        { key: 'actionx', label: 'Action', sortable: true, class: 'text-left' }
      ],
    }
  },
  computed:{
    ...mapState([
      'director',
      'auth'
    ]),
    directors:{
      get(){
        return this.director.directors.data
      },
      set(val){
        this.$store.commit('director/updateDirectors', val)
      }
    },
  },
  mounted(){
    this.$store.dispatch('director/updateDirectors', {businessId:localStorage.orgId})
    const app = $('body')
    app.removeClass('show-aside') //: app.removeClass('show-aside')
  }
}
</script>
