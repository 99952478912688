import apiClient from "./BaseService";

export default {
    callReadCampaignsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("readCampaign", payload);
    },
    callCreateCampaignsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("createCampaign", payload);
    },
    callEditCampaignApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateCampaign", payload);
    },
    callReadSingleCampaignApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("readSingleCampaign", payload);
    },
    callReadActiveCampaignApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("readActiveCampaign", payload);
    },
    callUploadCampaignImageApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateImageCampaign", payload);
    },
    callRemoveCampaignImageApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("removeImageCampaign", payload);
    },
}