import apiClient from "./BaseService";

export default {
  callLogonApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("genLogon", payload);
  },
  callLogoutApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("genLogout", payload);
  },
  callInitiateEnrollmentApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("initiateGenReg", payload);
  },
  callCompleteEnrollmentApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("completeGenReg", payload);
  },
  callCreateCompanyApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("createcompany", payload);
  },
  callReadCompanyApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("readCompanies", payload);
  },
  callInitiateResetPasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("initiateGenResetPass", payload);
  },
  callCompleteResetPasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("completeResetPassword", payload);
  },
  callResendOtpApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("resendOtp", payload);
  },
  callVerifyTokenApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("validateUserToken", payload);
  },
  callChangePasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("changeGenPassword", payload);
  },
  callGetUsersApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.qooSquareProfile.post("userReadAll", payload);
  },
  callVerifyUsernamesApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("genVerifyUsername", payload);
  },
  callVerifyOtpApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("verifyToken", payload);
  },
  callRetrieveAuthorizationApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgId;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.singleSignOn.post("retrieveAuthorization", payload);
  },
};
