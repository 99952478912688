<template>
    <div class="row">
        <div class="col-md-12 mg-t-10">
            <div class="card ht-100p">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h6 class="mg-b-0">Personal Profile</h6>
                    <div class="d-flex tx-18">

<!--                        <a href="" class="link-03 lh-0"><i class="icon ion-md-refresh"></i></a>-->
<!--                        <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a>-->
                    </div>
                </div>
                <p v-if="auth.userInfo == null" align="center" class="mt-3"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>
                <b-form v-else class="p-4">
                    <b-form-row>
                        <b-col md="6">
                            <b-form-group label-for="firstname" label="First Name">
                                <b-input id="firstname" v-model="auth.userInfo.firstName" readonly></b-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label-for="lastname" label="Last Name">
                                <b-input id="lastname" v-model="auth.userInfo.lastName" readonly></b-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label-for="phone" label="Phone Number">
                                <b-input id="phone" v-model="auth.userInfo.mobileNumber" readonly></b-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label-for="email" label="Email Address">
                                <b-input id="email" v-model="auth.userInfo.email" readonly></b-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label-for="lastlogin" label="Last Login Date">
                                <b-input id="lastlogin" v-model="auth.userInfo.userLastLoginDate" readonly></b-input>
                            </b-form-group>
                        </b-col>
<!--                        <b-col md="4">-->
<!--                            <b-form-group label-for="dateEnrolled" label="Date Enrolled">-->
<!--                                <b-input id="dateEnrolled" v-model="auth.userInfo.userCreatedAt" readonly></b-input>-->
<!--                            </b-form-group>-->
<!--                        </b-col>-->
                    </b-form-row>
                </b-form>
            </div><!-- card -->
        </div>
    </div><!-- row -->
</template>

<script>
    // import FlotChart from "../FlotChart";
    import {mapState} from 'vuex'
    export default {
        name: "TopCards",
        components:{

        },
        data(){
            return {
                value: [
                    { name: 'Javascript', code: 'js' }
                ],
                options: [

                ],
                model:{
                    orgId:'',
                    canview:'',
                    cantrans:'',
                    role:'',
                    custusername:'',
                    fullname:'',
                    mobilenumber:'',
                    emailaddress:''
                },
                roles: ['initiator','verifier','authorizer']
            };
        },
        methods: {
            showAlert(title, message) {
                const options = {title: title, size: 'sm'}
                this.$dialogs.alert(message, options)
                    .then()
            },
            addTag (newTag) {
                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.value.push(tag)
            }
        },
        computed:{
            ...mapState([
                'auth'
            ])
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 900px) {
        .top-card {
            width: 103%;
        }
    }
    @media only screen and (min-width: 707px) and (max-width: 900px) {
        .top-card {
            width: 104%;
        }
    }

    @media only screen and (min-width: 517px)  and (max-width: 707px) {
        .top-card {
            width: 105%;
        }
    }

    @media only screen and (min-width: 451px)  and (max-width: 517px) {
        .top-card {
            width: 106%;
        }
    }

    @media only screen  and (max-width: 451px) {
        .top-card {
            width: 107%;
        }
    }
    .h-100 {
        height: 100vh !important;
    }
    @media only screen and  (max-width: 991px) {
        .extra{
            display: none;
        }
    }
    .image-input{
        display: block;
        width: 100%;
        height: 300px;
        cursor: pointer;
        background-size: cover;
        background-position: center center;
    }

    .placeholder{
        background: #F0F0F0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        font-size: 18px;
        font-family: Helvetica,serif;
    }

    .placeholder:hover{
        background: #E0E0E0
    }

    .file-input{
        display: none;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>