<template>
  <div>
    <b-button @click="showModal = !showModal" variant="secondary"><b-icon-plus/> Add New</b-button>
    <base-table
        :items="customerProjects"
        :fields="fields"
        :show-title="true"
        :is-busy="projection.projectionLoading"
        class="mt-2"
    ></base-table>
    <customer-modal :show="showModal" :row="{}"/>
  </div>
</template>

<script>
import BaseTable from "@/components/table/BaseTable";
import {mapState} from 'vuex'
import CustomerModal from "@/components/modal/projection/CustomerModal";
export default {
  name: "CustomerProjectionTable",
  components:{
    BaseTable,
    CustomerModal
  },
  data(){
    return {
      showModal: false,
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'customerProjectionAcquisition', label: 'Acquisition', sortable: true, sortDirection: 'desc', class: 'text-right' },
        { key: 'customerProjectionType', label: 'Type', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'customerProjectionMonth', label: 'Month', sortable: true, class: 'text-left' },
        { key: 'customerProjectionCashComponent', label: 'Cash Component', sortable: true, class: 'text-right' },
        { key: 'customerProjectionStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'customerProjectionCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
        { key: 'actionsCustomer', label: 'Action', sortable: true, class: 'text-left' }
      ],
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('projection/updateCustomerProjection',{customerProjectionCampaignId: localStorage.campaignId})
    }
  },
  computed:{
    customerProjects:{
      get(){
        return this.projection.customerProjections.data
      },
      set(val){
        this.$store.commit('projection/updateCustomerProjection', val)
      }
    },
    ...mapState([
        'auth',
        'projection'
    ])
  },
  mounted() {
    this.loadData();
  },
}
</script>

<style scoped>

</style>