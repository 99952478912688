<template>
  <!-- Two-way Data-Binding -->
  <div>
    <b-form @submit.prevent="updateCampaign()">
      <quill-editor
          ref="myQuillEditor"
          v-model="model.campaignDescription"
          :options="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
      />
      <br/>
      <p style="text-align: right;"><b-button :disabled="this.campaign.campaignsLoading" type="submit" class="btn btn-outline-facebook">
        <b-spinner v-if="this.campaign.campaignsLoading" small/>
        Save
      </b-button></p>
    </b-form>

  </div>

  <!-- Or manually control the data synchronization -->
  <!--  <quill-editor-->
  <!--      :content="content"-->
  <!--      :options="editorOption"-->
  <!--      @change="onEditorChange($event)"-->
  <!--  />-->
</template>

<script>
// You can also register Quill modules in the component
// import Quill from 'quill'
// import someModule from '../yourModulePath/someQuillModule.js'
// Quill.register('modules/someModule', someModule)
// import BaseUploadCard from "@/components/card/BaseUploadCard";
// import BaseButton from "@/components/button/BaseButton";
import {mapState} from 'vuex';

export default {
  components:{
    // BaseUploadCard,
    // BaseButton
  },
  data () {
    return {
      model:{
        campaignId: '',
        campaignTitle: '',
        campaignTagline: '',
        campaignDescription: '',
        campaignTarget: '',
        campaignTags:'',
        campaignDuration: '',
        campaignImageOne: '',
        campaignImageTwo: '',
        campaignImageThree: '',
        campaignImageFour: '',
        campaignVideo: '',
        campaignWebsite: '',
        campaignStatus: '',
        campaignCreatedAt: '',
        campaignUpdatedAt: ''
      },
      content: '<h2>I am Example</h2>',
      editorOption: {
        // Some Quill options...
      }
    }
  },
  methods: {
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    updateCampaign(){
      this.$store.dispatch('campaign/editCampaign', this.model)
    }
  },
  computed: {
    ...mapState([
      'campaign',
      'auth'
    ]),
    modelMock(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.model = this.campaign.campaigns.data.filter(it => it.campaignId == localStorage.campaignId)[0]
      return this.model
    },
    readData(){
      return {campaignOrgId:localStorage.orgId}
    },
    campaignId() {
      return localStorage.campaignId
    },
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.model = this.campaign.campaigns.data.filter(it => it.campaignId == localStorage.campaignId)[0]
    console.log('this is current quill instance object', this.editor)
  }
}
</script>

