<template>
  <div>
    <span v-if="payslip.screen === 'passcode'">
      <passcode-form form-type="payslip"/>
    </span>
    <span class="resume-app" v-else>
      <div class="hero-first-section">
        <div class="first-navyBlue-control">
          <div class="row">
            <div class="col-md-5">
              <div class="first-group">
                <div class="dp-control">
                  <img :src="payslip.employeePayslips.employeeProfileImage" alt="dp" class="dp">
                </div>

                <p class="profile-top-header">Profile</p>

                <div class="underline-first">
                </div>


                <div class="profile-control">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">First Name</p>
                        <p class="profile-first-text">{{ payslip.employeePayslips.employeeFirstName }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Middle Name</p>
                        <p class="profile-first-text">{{ payslip.employeePayslips.employeeMiddleName }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Surname</p>
                        <p class="profile-first-text">{{ payslip.employeePayslips.employeeLastName }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Gender</p>
                        <p class="profile-first-text">{{ payslip.employeePayslips.employeeGender }}</p>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="profile-group">
                        <p class="profile-first-header">Phone</p>
                        <p class="profile-first-text">{{ payslip.employeePayslips.employeePhoneNumber }}</p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="profile-group">
                        <p class="profile-first-header">Email</p>
                        <p class="profile-first-text">{{ payslip.employeePayslips.employeeEmail?payslip.employeePayslips.employeeEmail.toLowerCase():"" }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="first-border"></div>

                <div class="employee-group">
                  <p class="employee-header">Payslips</p>
                  <div class="underline-second"></div>
                </div>

                <div v-for="employeePayslip in payslip.employeePayslips.data" v-bind:key="employeePayslip.employeePayslipId" class="red-container">

                    <div class="red-control">
                      <img :src="employeePayslip.employeePayslipEmployerLogo" alt="red-circle" class="red-circle">
                    </div>
                  <a href="#single-payslip" @click="getCurrentPayslip(employeePayslip.employeePayslipId)">
                    <div class="red-rubies">
                      <p class="red-rubies-header">{{ employeePayslip.employeePayslipRemark | titleCase }}</p>
                      <p class="red-rubies-date">{{ employeePayslip.employeePayslipEmployerName | titleCase }}
                        <br>NGN {{ employeePayslip.employeePayslipAmount | formatAmount }}</p>
                    </div>
                  </a>
                </div>

              </div>


            </div>



            <div class="col-md-7" id="single-payslip">
              <div class="second-group">
                <div class="logo-control">
                  <img src="@/assets/img/drawable-xxhdpi/logo.png" alt="logo" class="logo">
                </div>
                <strong>{{ currentPayslip[0]?currentPayslip[0].employeePayslipRemark.toUpperCase():'' }}</strong><br>
                <strong>NET PAY: NGN {{getEarnings().reduce((init, curr) => (
                    init+=parseFloat(curr.payslipDetailAmount==null?'0.00':curr.payslipDetailAmount.toString().replace(/,/g,''))
                ),0).toFixed(2) - getDeduction().reduce((init, curr) => (
                    init+=parseFloat(curr.payslipDetailAmount==null?'0.00':curr.payslipDetailAmount.toString().replace(/,/g,''))
                ),0).toFixed(2) | formatAmount}}</strong>
                <div class="second-border"></div>

                <p  class="section-header">Earnings (NGN {{getEarnings().reduce((init, curr) => (
                    init+=parseFloat(curr.payslipDetailAmount==null?'0.00':curr.payslipDetailAmount.toString().replace(/,/g,''))
                ),0).toFixed(2) | formatAmount}})</p>

                <div v-for="extra in getEarnings()" v-bind:key="extra.payslipDetailId" class="orange-stick-container">
                  <div class="orange-stick-control-first">
                    <img src="@/assets/img/drawable-xxhdpi/orange-stick.png" alt="orange-stick" class="orange-stick">
                  </div>
                  <div class="orange-stick-rubies-first">
                    <p class="orange-stick-second-text">{{ extra.payslipDetailRemark | titleCase }}</p>
                    <p class="orange-stick-date">NGN {{ extra.payslipDetailAmount | formatAmount}}</p>
                  </div>
                </div>

                <div class="second-border"></div>

                <p   class="section-header">Deductions (NGN {{getDeduction().reduce((init, curr) => (
                    init+=parseFloat(curr.payslipDetailAmount==null?'0.00':curr.payslipDetailAmount.toString().replace(/,/g,''))
                ),0).toFixed(2) | formatAmount}})</p>

                <div v-for="extra in getDeduction()" v-bind:key="extra.payslipDetailId" class="orange-stick-container">
                  <div class="orange-stick-control-first">
                    <img src="@/assets/img/drawable-xxhdpi/orange-stick.png" alt="orange-stick" class="orange-stick">
                  </div>
                  <div class="orange-stick-rubies-first">
                    <p class="orange-stick-second-text">{{ extra.payslipDetailRemark | titleCase }}</p>
                    <p class="orange-stick-date">NGN {{ extra.payslipDetailAmount | formatAmount}}</p>
                  </div>
                </div>


              </div>

            </div>


          </div>
        </div>
      </div>
      <div class="third-border"></div>
      <div class="second-footer">
        <div class="">
          <div class="logo-footer-control">
            <img src="@/assets/img/drawable-xxhdpi/logo-footer.png" alt="logo-footer" class="logo-footer">
          </div>
          <div class="footer-top">
            <div class="row">
              <div class="col-md-12">
                <div class="after-logo-footer-text">
                  <p class="logo-footer-text-one">For more enquiries, contact - support@qusquares.com</p>
                  <p class="logo-footer-text-two">©Qusquares 2020. All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>

  </div>
</template>

<script>
// import BaseTable from "@/components/table/BaseTable";
import PasscodeForm from "@/components/form/PasscodeForm";
import {mapState} from 'vuex'
export default {
  name: "Payslips",
  data(){
    return {
      currentPayslip:[]
    }
  },
  components:{
    PasscodeForm
  },
  computed:{
    ...mapState([
      'payslip'
    ]),
  },
  methods:{
    // eslint-disable-next-line no-unused-vars
    getCurrentPayslip(_id){
      this.currentPayslip = this.payslip.employeePayslips.data.filter(it => it.employeePayslipId === _id)
      this.currentPayslip = this.currentPayslip?this.currentPayslip:[]
    },
    getEarnings(){
      if (this.currentPayslip[0] == null) return []
      return this.currentPayslip[0].employeePayslipDetails.filter(it => it.payslipDetailType === 'EARNINGS')
    },
    getDeduction(){
      if (this.currentPayslip[0] == null) return []
      return this.currentPayslip[0].employeePayslipDetails.filter(it => it.payslipDetailType === 'DEDUCTIONS')
    }
  },
  mounted() {
    this.$store.commit('payslip/updateScreen', 'passcode')
  }
}
</script>

<style scoped>
@import "../../assets/style/resume/luminous.css";
@import "../../assets/style/resume/style.css";
</style>