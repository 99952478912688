<template>
  <div>
    <b-button @click="showModal = !showModal" variant="secondary"><b-icon-plus/> Add New</b-button>
    <base-table
        :items="saleProjects"
        :fields="fields"
        :show-title="true"
        :is-busy="projection.projectionLoading"
        class="mt-2"
    ></base-table>
    <sales-modal :show="showModal" :row="{}"/>
  </div>
</template>

<script>
import BaseTable from "@/components/table/BaseTable";
import {mapState} from 'vuex'
import SalesModal from "@/components/modal/projection/SalesModal";
export default {
  name: "SalesProjectionTable",
  components:{
    SalesModal,
    BaseTable
  },
  data(){
    return {
      showModal: false,
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'saleProjectionAmount', label: 'Amount', sortable: true, sortDirection: 'desc', class: 'text-right' },
        { key: 'saleProjectionType', label: 'Type', sortable: true, sortDirection: 'desc', class: 'text-left' },
        { key: 'saleProjectionMonth', label: 'Month', sortable: true, class: 'text-left' },
        { key: 'saleProjectionCashComponent', label: 'Cash Component', sortable: true, class: 'text-right' },
        { key: 'saleProjectionStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'saleProjectionCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
        // { key: 'saleProjectionUpdatedAt', label: 'Updated At', sortable: true, class: 'text-left' },
        { key: 'actionsSales', label: 'Action', sortable: true, class: 'text-left' }
      ],
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('projection/updateSalesProjection',{saleProjectionCampaignId: localStorage.campaignId})
    }
  },
  computed:{
    saleProjects:{
      get(){
        return this.projection.salesProjections.data
      },
      set(val){
        this.$store.commit('projection/updateSalesProjection', val)
      }
    },
    ...mapState([
        'auth',
        'projection'
    ])
  },
  mounted() {
    this.loadData();
  },
}
</script>

<style scoped>

</style>