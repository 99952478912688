import apiClient from "./BaseService";

export default {
    callReadDocumentsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null) {
            payload.countryId = localStorage._countryId;
        }
        return apiClient.qooSquareProfile.post("documentRead", payload);
    },
    callUploadDocumentsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null) {
            payload.countryId = localStorage._countryId;
        }
        return apiClient.qooSquareProfile.post("documentCreate", payload);
    },
    callRemoveDocumentsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null) {
            payload.countryId = localStorage._countryId;
        }
        return apiClient.qooSquareProfile.post("documentUpdateStatus", payload);
    },
}