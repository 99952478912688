import ResumeService from "@/service/ResumeService";
// import swal from "sweetalert";
export const state = {
    screen:'passcode',
    employeeResume:{
        employeeBvn: '',
        employeeCreatedAt: '',
        employeeDob: '',
        employeeEmail: '',
        employeeFirstName: '',
        employeeGender: '',
        employeeId: '',
        employeeIdIssueDate: '',
        employeeIdNumber: '',
        employeeIdType: '',
        employeeLastName: '',
        employeeMaker: '',
        employeeMiddleName: '',
        employeeNationality: '',
        employeePhoneNumber: '',
        employeeStatus: '',
        employeeUpdateDate: '',
        employeeUpdatedAt: '',
        employeeUserType: '',
        employeeCountryCode: '',
        employeeAccountBank: '',
        employeeAccountCreatedAt: '',
        employeeAccountDate: '',
        employeeAccountEmployeeId: '',
        employeeAccountEmployerId: '',
        employeeAccountId: '',
        employeeAccountNumber: '',
        employeeAccountUpdatedAt: '',
        employeeDetails: [],
        educations: [],
        skills: [],
        projects: [],
        responseCode: '',
        responseMessage: ''
    },
    resumeLoading: false,

}

export const getters = {

}
export  const mutations = {
    updateEmployeeResume:(state, payload) =>{
        state.employeeResume = payload
    },
    updateResumeLoading:(state, payload) =>{
        state.resumeLoading = payload
    },
    updateScreen:(state, payload) =>{
        state.screen = payload
    },

}
export  const actions = {

    updateResume:({commit}, payload) => {
        commit('updateResumeLoading', true)
        return ResumeService.callReadSharedResumeApi(payload)
            .then(response => {
                commit('updateResumeLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateEmployeeResume', responseData)
                    commit('updateScreen', 'resume')
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateResumeLoading", false)
                //alert(error)
                // throw error;
            });
    }
}







