import DirectorService from "@/service/DirectorService";
import swal from "sweetalert";
import router from "@/router";
import store from "@/store/store";

export const state = {
    screen:'formOne',
    directors:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    directorIdTypes:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    companyTypes:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    companyIndustries:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    response:{
        responseCode: '',
        firstName: '',
        countrycode: '',
        responseMessage: '',
        phoneNumber: '',
        uniqueRef: '',
        source: '',
        hasAccount: '',
        email: '',
        username: '',
        lastName: ''
    },
    directorsLoading: false,
    directorsImageLoading: false,
    typesLoading: false
}
export  const getter = {

}
export  const mutations = {
    updateDirectors:(state, payload) =>{
        state.directors = payload
    },
    updateDirectorIdTypes:(state, payload) =>{
        state.directorIdTypes = payload
    },
    updateCompanyTypes:(state, payload) =>{
        state.companyTypes = payload
    },
    updateCompanyIndustries:(state, payload) =>{
        state.companyIndustries = payload
    },
    updateDirectorsLoading:(state, payload) =>{
        state.directorsLoading = payload
    },
    updateTypesLoading:(state, payload) =>{
        state.typesLoading = payload
    },
    updateDirectorsImageLoading:(state, payload) =>{
        state.directorsImageLoading = payload
    },
    updateScreen:(state, payload) =>{
        state.screen = payload
    },
    updateResponse:(state, payload) =>{
        state.response = payload
    },
}
export const actions = {
    updateDirectors: ({commit}, payload) => {
        commit('updateDirectorsLoading', true)
        return DirectorService.callReadDirectorsApi(payload)
            .then(response => {
                commit('updateDirectorsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateDirectors', responseData)
                    commit('updateScreen', 'director')
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDirectorsLoading", false)
                //alert(error)
                // throw error;
            });
    },
    updateDirectorIdTypes: ({commit}, payload) => {
        commit('updateTypesLoading', true)
        return DirectorService.callReadDirectorTypesApi(payload)
            .then(response => {
                commit('updateTypesLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateDirectorIdTypes', responseData)
                    commit('updateScreen', 'director')
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateTypesLoading", false)
                //alert(error)
                // throw error;
            });
    },
    updateCompanyTypes: ({commit}, payload) => {
        commit('updateTypesLoading', true)
        return DirectorService.callReadCompanyTypesApi(payload)
            .then(response => {
                commit('updateTypesLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateCompanyTypes', responseData)
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateTypesLoading", false)
                //alert(error)
                // throw error;
            });
    },
    updateCompanyIndustries: ({commit}, payload) => {
        commit('updateTypesLoading', true)
        return DirectorService.callReadCompanyIndustriesApi(payload)
            .then(response => {
                commit('updateTypesLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateCompanyIndustries', responseData)
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateTypesLoading", false)
                //alert(error)
                // throw error;
            });
    },

    createDirector: ({commit}, payload) => {
        commit('updateDirectorsLoading', true)
        return DirectorService.callCreateDirectorsApi(payload)
            .then(response => {
                console.log('updateDirectorsLoading>>>>>>false')
                commit('updateDirectorsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    // eslint-disable-next-line no-unused-vars
                    swal('Success!', responseData.responseMessage, 'success').then(res=>{router.go(-1)});
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDirectorsLoading", false)
                //alert(error)
                // throw error;
            });
    },
    initiateDirectorComplete: ({commit}, payload) => {
        commit('updateDirectorsLoading', true)
        return DirectorService.callDirectorInitiateCompleteApi(payload)
            .then(response => {
                commit('updateDirectorsLoading', false)
                let responseData = response.data
                commit('updateResponse', responseData)
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success').then(()=>{
                        commit('updateScreen', 'formTwo')
                    })
                }else if (responseData.responseCode === '01') {
                    swal('Info!', responseData.responseMessage, 'warning').then(()=>{
                        commit('updateScreen', 'formThree')
                    })
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDirectorsLoading", false)
                //alert(error)
                // throw error;
            });
    },
    finalizeDirectorComplete: ({commit}, payload) => {
        commit('updateDirectorsLoading', true)
        return DirectorService.callDirectorFinalizeCompleteApi(payload)
            .then(response => {
                commit('updateDirectorsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                        .then(()=>{
                            router.push('/')
                        })
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDirectorsLoading", false)
                //alert(error)
                // throw error;
            });
    },
    credentialDirectorComplete: ({commit}, payload) => {
        commit('updateDirectorsLoading', true)
        return DirectorService.callDirectorCredentialCompleteApi(payload)
            .then(response => {
                commit('updateDirectorsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    swal('Success!', responseData.responseMessage, 'success')
                        .then(()=>{
                            localStorage.token = responseData.token;
                            store.commit("auth/updateAuthToken", responseData.token);
                            store.commit("auth/updateUser", responseData);
                            if(localStorage.orgId == null && store.getters['auth/getUser'].companies.data[0] != null){
                                localStorage.orgId = store.getters['auth/getUser'].companies.data[0].orgId
                            }
                            console.log("???????????????????????????????????????????????????")
                            router.push('/dashboard')
                        })
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDirectorsLoading", false)
                //alert(error)
                // throw error;
            });
    },
    uploadDirectorImage: ({commit}, payload) => {
        commit('updateDirectorsImageLoading', true)
        return DirectorService.callUploadDirectImageApi(payload)
            .then(response => {
                commit('updateDirectorsImageLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    console.log('Upload successful!')
                    //swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDirectorsImageLoading", false)
                //alert(error)
                // throw error;
            });
    },
}