import { render, staticRenderFns } from "./DirectorRegistration.vue?vue&type=template&id=3a9746b2&scoped=true&"
import script from "./DirectorRegistration.vue?vue&type=script&lang=js&"
export * from "./DirectorRegistration.vue?vue&type=script&lang=js&"
import style0 from "./DirectorRegistration.vue?vue&type=style&index=0&id=3a9746b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9746b2",
  null
  
)

export default component.exports