import AuthService from "../../service/AuthService";
import {stage} from "../../app.config"
import swal from 'sweetalert'
import router from "@/router";
//import route from '@/router/routes'

//export const namespaced = true

export const state = {
    token:null,
    isDocumentsUpt: false,
    authLoading: false,
    deviceId: '',
    loginRequest: {},
    screen: 'recovery1',
    orgScreen: 'stepTwo',
    loginScreen: 'login',
    regScreen: 'reg',
    uuId:'',
    userInfo: {
        accountNumber: '',
        countryCode: '',
        token: '',
        companies: {
            data: [],
            responseCode: '',
            responseMessage: ''
        },
        customerId: '',
        customerName: '',
        email: '',
        customerEmail: '',
        role: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
        responseCode: '',
        responseMessage: '',
        id: '',
        username: '',
        stage: ''
    },
    users: {
        totalUsers: "0",
        totalPendingUsers: "0",
        totalActiveUsers: "0",
        totalAdminUsers: "0",
        data: []
    },
    response:{}

}


export const getters = {
    getTok: state => {
        // console.log("getter getTok")
        return state.token
    },
    getUser: state => {
        // console.log("getter getTok")
        return state.userInfo
    },
    activeCompany: state => {
        // console.log("getter getTok")
        return state.userInfo.companies.data.filter(it=>it.orgId === localStorage.orgId)[0]
    }
}


export const mutations = {
    updateResponse:(state, payload) =>{
        state.response = payload
    },

    updateOrgScreen:(state, payload) =>{
        state.orgScreen = payload
    },

    updateLoginRequest:(state, payload) =>{
        state.loginRequest = payload
    },

    updateCompanies:(state, payload) =>{
        state.userInfo.companies = payload
    },

    updateDeviceId: (state, payload) =>{
        state.deviceId = payload
    },

    updateUsers: (state, payload) =>{
        state.users = payload
    },

    updateAuthLoading: (state, payload) =>{
        state.authLoading = payload
    },

    updateScreen: (state, payload) =>{
        state.screen = payload
    },

    updateLoginScreen: (state, payload) =>{
        state.loginScreen = payload
    },

    updateUser: (state, payload) =>{
        state.userInfo = payload
    },

    updateRegScreen: (state, payload) =>{
        state.regScreen = payload
    },

    updateAuthToken: (state, payload) =>{
        console.log("mutation updateAuthToken ==>>", payload)
        state.token = payload
    },

    generateUUID: (state, payload) =>{ // Public Domain/MIT
        let d = new Date().getTime();
        console.log(payload)
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        state.uuId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        }).toUpperCase();
    },

    // eslint-disable-next-line no-unused-vars
    reset: (state, payload) =>{
        state.token = null;
        state.isDocumentsUpt = false;
        state.authLoading = false;
        state.screen = 'login';
        state.regScreen = 'reg';
        state.response ={};
    },
}


export const actions = {
    // eslint-disable-next-line no-unused-vars
    logon: ({ commit, dispatch, state }, payload)=>{
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        commit("generateUUID", {})
        payload.mobiledeviceid = state.uuId
        return AuthService.callLogonApi(payload)
        .then(response => {
            let responseData = response.data;
            commit("updateResponse", responseData);
            if (responseData.responseCode === "00") {
                localStorage.token = payload.mobiledeviceid;
                commit("updateAuthToken", payload.mobiledeviceid);
                commit("updateUser", responseData);
                dispatch('readCompany',{userId: responseData.id, requestType: 'company'}).then(()=>{
                    commit("updateAuthLoading", false);
                    console.log(state.userInfo)
                    if(localStorage.orgId == null && state.userInfo.companies.data != null){
                        if (state.userInfo.companies.data.length > 0)
                            localStorage.orgId = state.userInfo.companies.data[0].orgId
                    }
                    dispatch('navigate',{source: localStorage.source, deviceId: payload.mobiledeviceid})
                })

            }else if (responseData.responseCode === "01") {
                commit("updateAuthLoading", false);
                swal(
                    'Info',responseData.responseMessage,'info'
                ).then(res => {
                    console.log(res);
                    commit("updateLoginScreen", 'otp')
                });

            }else if (responseData.responseCode === '95'){
                commit("updateAuthLoading", false);
                swal(
                    'Error',responseData.responseMessage,'error'
                ).then(res => {
                    console.log(res);
                    router.push('/forgot-password');
                });
            }else {
                commit("updateAuthLoading", false);
                swal('Error!',responseData.responseMessage,'error').then();
            }
            
        })
        .catch((error) => {
            commit("updateAuthLoading", false);
            alert(error+" login");
            // throw error;
            // return Promise.reject(error)
        });
    },

    validate({ commit }) {
        //getting token from local storage
        let rtn = false
        let userToken = localStorage.token;

        //start App Loading
        commit("updateAuthLoading", true);

        if (userToken === "") {
            //stop App Loading
            commit("updateAuthLoading", false);
            if (localStorage.source == null)
                this.$router.push('/')
            else
                this.$router.push('/login/'+localStorage.source)
            // return rtn
        } else {
            let payload = {
                token: ""
            };
            return AuthService.callVerifyTokenApi(payload)
                .then(response=>{
                    commit("updateAuthLoading", false);
                    let responseData = response.data;
                    if (responseData.responseCode === '00'){
                        commit("updateUser", responseData);
                        rtn = true
                        return rtn
                    }else{
                        return rtn
                    }
                })
                // eslint-disable-next-line no-unused-vars
                .catch(error=>{
                    commit("updateAuthLoading", false);
                    if (localStorage.source == null)
                        this.$router.push('/')
                    else
                        this.$router.push('/login/'+localStorage.source)
                    return rtn
                })
        }

    },

    initiateResetPassword: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        return AuthService.callInitiateResetPasswordApi(payload)
        .then(response => {
            let responseData = response.data;
            commit("updateResponse", responseData)
            if (responseData.responseCode === "00") {
                commit("updateAuthLoading", false)
                localStorage.clear()
                swal('Info!','Proceed to enter the 4 digit token sent to phone','info')
                    // eslint-disable-next-line no-unused-vars
                    .then(res=>{commit("updateScreen",'recovery2')});
            }else{
                commit("updateAuthLoading", false)
                swal('Error!',responseData.responseMessage,'error')
                    // eslint-disable-next-line no-unused-vars
                    .then(res=>{commit("updateScreen",'recovery1')});
            }
            
        })
        .catch(error => {
            commit("updateScreen",'recovery1')
            commit("updateAuthLoading", false)
            alert(error);
            // throw error;
        });
    },

    completeResetPassword: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        return AuthService.callCompleteResetPasswordApi(payload)
            .then(response => {
                let responseData = response.data;
                commit("updateAuthLoading", false)
                if (responseData.responseCode === "00") {
                    localStorage.clear();
                    swal('Success!',responseData.responseMessage,'success')
                        // eslint-disable-next-line no-unused-vars
                        .then(res=>{
                            commit("updateScreen",'recovery1')
                            router.push('/');
                        });
                }else{
                    swal('Error!',responseData.responseMessage,'error')
                        // eslint-disable-next-line no-unused-vars
                        .then(res=>{
                            commit("updateScreen",'recovery1')
                            // router.push('/');
                        });
                }

            })
            .catch(error => {
                commit("updateAuthLoading", false)
                alert(error);
                // throw error;
            });
    },

    verifyToken: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        return AuthService.callVerifyTokenApi(payload)
        .then(response => {
            let responseData = response.data;
            commit("updateAuthLoading", false)
            if (responseData.responseCode === "00") {
                // console.log("callVerifyTokenApi responseData ==>>", responseData);
                localStorage.clear()
                swal('Info!',responseData.responseMessage,'info')
                    // eslint-disable-next-line no-unused-vars
                    .then(res=>{commit("updateScreen",'recovery3')});
            }else{
                swal('Error!',responseData.responseMessage,'error')
                    // eslint-disable-next-line no-unused-vars
                    .then();
            }
            
        })
        .catch(error => {
            commit("updateAuthLoading", false)
            alert(error);
        });
    },

    changePassword: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        return AuthService.callChangePasswordApi(payload)
        .then(response => {
            let responseData = response.data;
            commit("updateAuthLoading", false)
            if (responseData.responseCode === "00") {
                swal('Success!',responseData.responseMessage,'success')
            }else{
                swal('Error!',responseData.responseMessage,'error')
            }

        })
        .catch(error => {
            commit("updateAuthLoading", false)
            alert(error);
        });
    },

    createCompany: ({ commit, state, dispatch }, payload)=>{
        commit("updateAuthLoading", true)
        console.log(state.loginRequest)
        return AuthService.callCreateCompanyApi(payload)
        .then(response =>  {
            let responseData = response.data;
            commit("updateAuthLoading", false);
            if (responseData.responseCode === "00") {
                dispatch('readCompany', {userId: payload.orgUserId, requestType: 'company'}).then(()=>{
                    router.push('/dashboard')
                })
                // eslint-disable-next-line no-unused-vars
                // swal('Success!',responseData.responseMessage,'success').then(result=>{
                //     localStorage.removeItem('token')
                //     router.push('/')
                //     //window.location = publicPath+'preloader'
                // })
            }else{
                swal('Error!',responseData.responseMessage,'error')
            }

        })
        .catch(error => {
            commit("updateAuthLoading", false)
            alert(error);
        });
    },

    readCompany: ({ state, commit }, payload)=>{
        //if(state.calenders.length < 1)
            //commit("updateCalenderLoading", true)
        return AuthService.callReadCompanyApi(payload)
            .then(response => {
                commit("updateAuthLoading", false)
                commit("updateCompanies", response.data)
                if(localStorage.orgId == null && state.userInfo.companies.data[0] != null){
                    localStorage.orgId = state.userInfo.companies.data[0].orgId
                }

            })
            .catch(error => {
                commit("updateAuthLoading", false)
                alert(error)
                // throw error;
            });
    },

    initiateEnrollment: ({ commit }, payload)=>{
        delete payload.dialogs;
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        return AuthService.callInitiateEnrollmentApi(payload)
        .then(response => {
            commit("updateAuthLoading", false)
            let responseData = response.data;
            commit("updateResponse", responseData)
            if (responseData.responseCode === "00") {
                swal('Info!','Proceed to enter the 4 digit token sent to phone/email','info').then(commit("updateRegScreen", 'otp'));
            }else{
                swal('Error!',responseData.responseMessage,'error')//.then(router.push('/').then);
            }
            
        })
        .catch((error) => {
            commit("updateAuthLoading", false)
            alert(error);
        });
    },

    completeEnrollment: ({ commit, dispatch }, payload)=>{
        delete payload.dialogs;
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        return AuthService.callCompleteEnrollmentApi(payload)
        .then(response => {
            commit("updateAuthLoading", false)
            let responseData = response.data;
            commit("updateResponse", responseData)
            if (responseData.responseCode === "00") {
                dispatch('logon',{username: payload.email, password: payload.password, source: 'WEB', countryCode: payload.countryCode}).then(()=>{
                    //commit("updateRegScreen", 'reg')
                })
                // swal('Success!',responseData.responseMessage,'success')
                //     // eslint-disable-next-line no-unused-vars
                //     .then(res=>{
                //         commit("updateRegScreen", 'reg')
                //         router.push('/')
                //     });
            }else{
                swal('Error!',responseData.responseMessage,'error').then();
            }
            
        })
        .catch((error) => {
            commit("updateAuthLoading", false)
            alert(error);
            // throw error;
            // return Promise.reject(error)
        });
    },

    updateUsers: ({ commit, state }, payload)=>{
        if(state.users.data.length < 1)
            commit("updateAuthLoading", true)
        return AuthService.callGetUsersApi(payload)
            .then(response => {
                commit("updateAuthLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateAuthLoading", false)
                    let responseData = response.data;
                    commit("updateUsers", responseData)
                }

            })
            .catch(error => {
                commit("updateAuthLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateUser: ({ commit }, payload)=>{
        commit("updateUser",payload)
    },

    navigate: ( {}, payload )=>{
        let deviceId = payload.deviceId
        console.log(">>>>>>>>>>ddddd>>>>>>>",deviceId)
        console.log('payload.source>>>>>>>',payload.source)
        if (payload.source == null) {
            router.push('/dashboard')
            return
        }
        else if (payload.source !== '')
            payload.source = payload.source+'.'
        let append = ''
        if (stage === 'dev')
            append = 'dev-'
        if (payload.source === '') append = append.replace('-','.')
        //payload.source = payload.source+'.'
        console.log('payload.source>>>>>>>',payload.source)
        window.location = deviceId == null?`https://${append}${payload.source}qoosquare.com`:`https://${append}${payload.source}qoosquare.com?data=`+deviceId
        // if (payload.source == null) {
        //     router.push('/dashboard')
        //     // if (stage === 'dev')
        //     //     //window.location = 'https://dev.qoosquare.com'
        //     // else
        //     //     //window.location = 'https://qoosquare.com'
        // }else if (payload.source === 'people') {
        //     if (stage === 'dev')
        //         window.location = deviceId == null?'https://dev-people.qoosquare.com':'https://dev-people.qoosquare.com?data='+deviceId
        //     else
        //         window.location = deviceId == null?'https://people.qoosquare.com':'https://people.qoosquare.com?data='+deviceId
        // }else if (payload.source === 'qoovest') {
        //     if (stage === 'dev')
        //         window.location = deviceId == null?'https://dev.qoovest.com':'https://dev.qoovest.com?data='+deviceId
        //     else
        //         window.location = deviceId == null?'https://qoovest.com':'https://qoovest.com?data='+deviceId
        // }else {
        //     if (stage === 'dev')
        //         window.location = deviceId == null?'https://dev.qoosquare.com':'https://dev.qoosquare.com?data='+deviceId
        //     else
        //         window.location = deviceId == null?'https://qoosquare.com':'https://qoosquare.com?data='+deviceId
        // }

        localStorage.removeItem('source')

    },

    reset: ({ commit }, payload)=>{
        commit("reset",payload)
    }

}
