<template>
  <div class="row">
    <div class="col-md-12 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">Documents </h6>
          <div class="d-flex tx-18">
            <!--                        <a class="link-03 lh-0 pr-3 tx-spacing&#45;&#45;3 tx-15" href="https://cooperateacc.s3.amazonaws.com/Rubies_Reference_Form_default_blank.pdf" target="_blank"><b-icon-download></b-icon-download>Download Reference</a>-->

            <!--                        <a href="#" class="link-03 lh-0"><b-icon-arrow-repeat/></a>-->
            <!--                        <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a>-->
          </div>
        </div>
        <p v-if="document.documentsLoading" class="mt-3" align="center"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>
        <b-row v-else class="mb-3 mt-3 mr-3 ml-3">
          <b-col v-for="document in document.documents.data" v-bind:key="document.orgDocId" class="mb-3" md="2">
            <upload-card
                :org-doc-name="document.orgDocName"
                :org-doc-status="document.orgDocStatus"
                :org-doc-url="document.orgDocUrl"
                :org-doc-id="document.orgDocId"
            ></upload-card>
          </b-col>
        </b-row>
      </div><!-- card -->
    </div>
  </div><!-- row -->
</template>

<script>
import {mapState} from 'vuex'
import UploadCard from "./UploadCard";
export default {
  name: "TopCards",
  components:{
    UploadCard
  },
  data(){
    return {

    };
  },
  methods: {
    computeFileInputRef(name){
      this.currentRef = name.toLowerCase().replace(" ", "").replace(" ", "").replace(" ", "")
      this.currentRef = this.currentRef.replace(",", "").replace(".", "")
      //console.log(this.currentRef)
      return this.currentRef
    },

    async readDocuments(){
      await this.$store.dispatch("document/updateDocuments", {
        orgId: localStorage.orgId,
        accID: localStorage.accID,
        readAll: "NO",
        requestType: 'document'
      }, {root: true})
    },

  },
  computed:{
    ...mapState([
      'auth',
      'company',
      'document',
      'officer'
    ]),
    accountsList:{
      get(){
        let acc = [];
        let len = this.company.company[0] == null? 0 : this.company.company[0].accounts.length;
        for (let i = 0; i < len; i++)
          acc[i] = {
            name: this.company.company[0].accounts[i].accNumber,
            code: this.company.company[0].accounts[i].accNumber
          };
        return acc;
      },
      set(){

      }
    }
  },
  mounted() {
    this.readDocuments()
  }
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
.h-100 {
  height: 100vh !important;
}
@media only screen and  (max-width: 991px) {
  .extra{
    display: none;
  }
}
.image-input{
  display: block;
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder{
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica,serif;
}

.placeholder:hover{
  background: #E0E0E0
}

.file-input{
  display: none;
}

.dropbox {
  z-index: 0;
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  /*padding: 10px 10px;*/
  /*min-height: 200px; !* minimum height *!*/
  position: relative;
  cursor: pointer;
}

.input-file {
  z-index: 0;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  /*padding: 50px 0;*/
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>