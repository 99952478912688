import apiClient from "./BaseService";

export default {
    callReadSharedResumeApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquare.post("readSharedResume", payload);
    },
}