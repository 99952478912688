import apiClient from "./BaseService";

export default {
    callGenerateQRApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquare.post("generateAttendanceQrCode", payload);
    },
    callCaptureQRApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquare.post("captureAttendanceQrCode", payload);
    },
    callReadAttendanceByEmployeeApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquare.post("readAttendanceByEmployee", payload);
    },
    callReadAttendanceByEmployerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquare.post("readAttendanceByEmployer", payload);
    },
    callEditAttendanceStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquare.post("updateAttendanceStatus", payload);
    }
}