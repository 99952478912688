import apiClient from "./BaseService";

export default {
    
    //EXPENSE PROJECTION
    callCreateExpenseProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("createExpenseProjection", payload);
    },
    callUpdateExpenseProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateExpenseProjection", payload);
    },
    callUpdateExpenseProjectionStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateStatusExpenseProjection", payload);
    },
    callReadExpenseProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("readExpenseProjection", payload);
    },

    //SALES
    callCreateSalesProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("createSaleProjection", payload);
    },
    callUpdateSalesProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateSaleProjection", payload);
    },
    callUpdateSalesProjectionStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateStatusSaleProjection", payload);
    },
    callReadSalesProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("readSaleProjection", payload);
    },

    //CUSTOMER
    callCreateCustomerProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("createCustomerProjection", payload);
    },
    callUpdateCustomerProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateCustomerProjection", payload);
    },
    callUpdateCustomerProjectionStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateStatusCustomerProjection", payload);
    },
    callReadCustomerProjectionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("readCustomerProjection", payload);
    },
    //Project Tracker
    callCreateProjectTrackerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("createProjectTracker", payload);
    },
    callUpdateProjectTrackerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateProjectTracker", payload);
    },
    callUpdateStatusProjectTrackerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("updateStatusProjectTracker", payload);
    },
    callReadProjectTrackerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgId;
        if (localStorage._countryId != null)
            payload.countryId = localStorage._countryId;
        return apiClient.qooSquareProfile.post("readProjectTracker", payload);
    }
}