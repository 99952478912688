<template>
  <div class="content-body">
    <div class="container pd-x-0">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item"><a href="#">Onboarding</a></li>
              <li class="breadcrumb-item active" aria-current="page">Documents</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">Documents</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.firstName}} {{auth.userInfo.lastName}}</p>
          <!--          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.lastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>-->
        </div>
        <div class="d-none d-md-block">
          <new-account-button></new-account-button>
          <router-link to="/new-officer" class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><plus-icon class="wd-10 mg-r-5"/>Director's Details</router-link>
          <a href="https://cooperateacc.s3.amazonaws.com/Rubies_Reference_Form_default_blank.pdf" target="_blank" style="background-color: #6d0606; border-color: #6d0606;" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><b-icon-download></b-icon-download> Download Reference</a>
        </div>
      </div>

      <!--TODO TOP CARDS-->
      <top-cards></top-cards>
      <!--TODO GROWTH-->
      <div v-if="auth.userInfo.companies.data.length > 0">
        <documents-card></documents-card>
      </div>
      <div v-else>
        <hr/>
        <p style="text-align: center; margin-top: 5%;"><img src="@/assets/img/empty.svg"></p>
        <p style="text-align: center;">No Business Created. <router-link to="/create-company">Click Here</router-link></p>
      </div>
      <!--TODO REVENUE-->
      <!-- <revenue></revenue>-->
      <!--TODO HISTORY-->
      <!-- <history></history>-->

    </div><!-- container -->
  </div>
</template>

<script>
// @ is an alias to /src
import TopCards from "../../components/card/TopCards";
// import Growth from "../../components/card/Growth";
import DocumentsCard from "../../components/card/DocumentsCard";
// import Revenue from "../../components/card/Revenue";
// import History from "../../components/card/History";
import {PlusIcon} from 'vue-feather-icons'
import {mapState} from 'vuex'
import NewAccountButton from "../../components/button/NewAccountButton";
import $ from "jquery";
export default {
  name: 'Home',
  components:{
    TopCards,
    DocumentsCard,
    PlusIcon,
    // FileIcon,
    NewAccountButton,
    // Growth,
    // Revenue,
    // History
  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState([
      'auth'
    ])
  },
  mounted(){
    const app = $('body')
    app.removeClass('show-aside') //: app.removeClass('show-aside')
  }
}
</script>
