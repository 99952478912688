<template>
    <div class="login">
        <b-row class="qoo-row">
            <b-col md="4">

            </b-col>
            <b-col md="4">
                <h1 class="title"><a href="#"><img class="login-logo" src="@/assets/img/qoosquarefulllogo.png"/></a></h1>
                <div style="display: block; text-align: center;">
                  <!-- FORM ONE-->
                    <b-form v-if="director.screen === 'formOne'" @submit.prevent="initiateComplete"  class="qoo-form">
                        <h3 style="text-align: center; padding-bottom: 5%;">Director Acknowledgement</h3>
                        <b-form-group>
                            <b-input v-model="model.directorBvn" placeholder="BVN"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <b-input v-model="model.directorBvnPhone" placeholder="Phone Number Registered with the BVN"></b-input>
                        </b-form-group>
                        <b-form-group>
                            <base-button title="Submit" :loading="director.directorsLoading"/>
                        </b-form-group>
                    </b-form>
                  <!--FORM TWO-->
                    <b-form v-if="director.screen === 'formTwo'" @submit.prevent="finalizeComplete"  class="qoo-form">
                        <h3 style="text-align: center; padding-bottom: 5%;">Director Acknowledgement</h3>
                        <b-form-group>
                            <b-input v-model="model.otp" placeholder="OTP"></b-input>
                        </b-form-group>
                        <b-form-group>
                          <the-mask placeholder="Password" type="password" required v-model="model.password" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
                        </b-form-group>
                        <b-form-group>
                          <the-mask placeholder="Retype Password" type="password" required v-model="model.passwordConfirmation" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
                        </b-form-group>
                        <b-form-group>
                            <base-button title="Submit" :loading="director.directorsLoading"/>
                        </b-form-group>
                    </b-form>
                  <!--FORM THREE-->
                    <b-form v-if="director.screen === 'formThree'" @submit.prevent="credentialComplete"  class="qoo-form">
                        <h3 style="text-align: center; padding-bottom: 5%;">Director Acknowledgement</h3>
                        <b-form-group>
                          <the-mask placeholder="Password" type="password" required v-model="model.password" maxlength="6" minlength="6" :mask="['######', '######']" class="form-control" />
<!--                          <p class="forgot-link" @click="$router.push('/forgot-password')">Forgot Password?</p>-->
                        </b-form-group>
                        <b-form-group>
                            <base-button title="Submit" :loading="director.directorsLoading"/>
                        </b-form-group>
<!--                        <p class="align-center">Not a member? <span @click="$router.push('/register')" class="span-link">Join QooSquare</span></p>-->
                    </b-form>
                </div>
            </b-col >
            <b-col md="4">

            </b-col>
        </b-row>
    </div>
</template>

<script>
    import BaseButton from "../../components/button/BaseButton";
    import {mapState} from 'vuex';
    // import bifrostCors from 'bifrost-cors'
    // import $ from 'jquery'
    export default {
        name: "Logon.vue",
        components:{
            BaseButton
        },
        data() {
          return {
            model: {
              directorId: '',
              directorBvn: '',
              directorBvnPhone: '',
              queryType: 'hashkey',
              password: '',
              pin: '1234',
              token: '',
              createAccount: 'YES',
              uniqueRef: '',
              countryCode: '234',
              passwordConfirmation: '',
              source: 'WEB',
              subscribe: true
            },
          }
        },
        methods:{
          async initiateComplete() {
            //localStorage.clear()
            await this.$store.dispatch("director/initiateDirectorComplete", this.model, { root: false })
            this.model.uniqueRef = this.director.response.uniqueRef
            this.model.password = ''
            this.model.passwordConfirmation = ''
            this.model.oldPassword = ''
            //this.$route.params
          },
          async finalizeComplete() {
            //localStorage.clear()
            await this.$store.dispatch("director/finalizeDirectorComplete", this.model, { root: false })
            this.model.password = ''
            this.model.passwordConfirmation = ''
            this.model.oldPassword = ''
            //this.$route.params
          },
          async credentialComplete() {
            //localStorage.clear()
            await this.$store.dispatch("director/credentialDirectorComplete", this.model, { root: false })
            this.model.password = ''
            this.model.passwordConfirmation = ''
            this.model.oldPassword = ''
            //this.$route.params
          },
        },
        computed:{
          ...mapState([
            'auth',
            'director',
          ]),

        },
        mounted() {
          this.$store.commit('director/updateScreen', 'formOne')
          this.model.directorId = this.$route.params.directorId
        }
    }
</script>

<style scoped>
  @import "../../assets/style/css/style.css";
</style>