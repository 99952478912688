<template>
  <div class="content-body pd-0">
    <div class="mail-wrapper mail-wrapper-two">
      <div class="mail-group" style="overflow-y:scroll; height: 100%;">
        <div class="mail-group-header justify-content-between">
          <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">Campaign Single View</h6>
          <div class="dropdown tx-13">
            <h6 class="tx-uppercase tx-semibold mg-b-0 mg-t-20">
              <refresh-ccw-icon @click="loadCampaigns" style="cursor: pointer;" title="Reload campaign"/>
             </h6>
            <!--                        <span class="tx-color-03">Sort:</span> <a href="" class="dropdown-link link-02">Date</a>-->
          </div><!-- dropdown -->
        </div><!-- mail-group-header -->
        <div class="mail-group-body">

          <label class="mail-group-label">Sub menu</label>
          <bar-loader v-if="campaign.campaignsLoading" class="custom-class" color="#6d0606" loading="loading" style="width: 100%;" sizeUnit="px"></bar-loader>
          <ul class="list-unstyled media-list mg-b-0">
            <li @click="selectCampaign('CD')" :class="activeClass(screen,'CD')">
                <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial('Campaign Details')}}</span></div>
                <div class="media-body mg-l-15">
                    <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                        <span class="tx-12">Campaign Details</span>
                    </div>
                    <span class="tx-11">This displays the full details of the campaign</span>
                </div><!-- media-body -->
            </li>
            <li @click="selectCampaign('CS')" :class="activeClass(screen,'CS')">
                <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial('Campaign Story')}}</span></div>
                <div class="media-body mg-l-15">
                    <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                        <span class="tx-12">Campaign Story</span>
                    </div>
                    <span class="tx-11">Story or full description concerning the campaign</span>
                </div><!-- media-body -->
            </li>
            <li @click="selectCampaign('CI')" :class="activeClass(screen,'CI')">
                <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial('Campaign Images')}}</span></div>
                <div class="media-body mg-l-15">
                    <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                        <span class="tx-12">Campaign Images</span>
                    </div>
                    <span class="tx-11">Here you can view and change the campaign images</span>
                </div><!-- media-body -->
            </li>
            <li @click="selectCampaign('SP')" :class="activeClass(screen,'SP')">
              <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial('Sales Projection')}}</span></div>
              <div class="media-body mg-l-15">
                <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                  <span class="tx-12">Sales Projections</span>
                </div>
                <span class="tx-11">Here you can view and change the campaign Sales Projections</span>
              </div><!-- media-body -->
            </li>
            <li @click="selectCampaign('EP')" :class="activeClass(screen,'EP')">
              <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial('Expense Projection')}}</span></div>
              <div class="media-body mg-l-15">
                <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                  <span class="tx-12">Expense Projections</span>
                </div>
                <span class="tx-11">Here you can view and change the campaign Expense Projections</span>
              </div><!-- media-body -->
            </li>
            <li @click="selectCampaign('CP')" :class="activeClass(screen,'CP')">
              <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial('Customer Projection')}}</span></div>
              <div class="media-body mg-l-15">
                <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                  <span class="tx-12">Customer Projections</span>
                </div>
                <span class="tx-11">Here you can view and change the campaign Customer Projections</span>
              </div><!-- media-body -->
            </li>
            <li @click="selectCampaign('PT')" :class="activeClass(screen,'PT')">
              <div class="avatar"><span class="avatar-initial rounded-circle bg-secondary">{{initial('Project Tracker')}}</span></div>
              <div class="media-body mg-l-15">
                <div class="tx-color-03 d-flex align-items-center justify-content-between mg-b-2">
                  <span class="tx-12">Project Tracker</span>
                </div>
                <span class="tx-11">Here you can view and change the campaign Project Tracker</span>
              </div><!-- media-body -->
            </li>
          </ul>
        </div><!-- mail-group-body -->
      </div><!-- mail-group -->

      <div class="mail-content">
        <div class="mail-content-header">
          <a href="" id="mailContentClose" class="link-02 d-none d-lg-block d-xl-none mg-r-20"><i data-feather="arrow-left"></i></a>
          <div class="media">
            <b-icon-arrow-left v-if="this.$vssWidth < 1200" @click="navigate()" style="height: 20px; width: 50px; margin-left: -20px; margin-right: -20px; cursor: pointer;" class=" mg-t-8"/>
            <div class="avatar avatar-sm  mg-l-10"><span class="avatar-initial rounded-circle bg-secondary">{{screen}}</span></div>
            <div class="media-body mg-l-10">
              <h6 class="mg-b-2 tx-13">{{ menu[screen][0] }}</h6>
              <span class="d-block tx-11 tx-color-03">{{ menu[screen][1] }}</span>
            </div><!-- media-body -->
          </div><!-- media -->
          <nav class="nav nav-icon-only mg-l-auto">
            <!--                        <a href="" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadCampaigns" style="cursor: pointer;" title="Reload conversation"/></a>-->
            <!--                        <a href="" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new conversation" @click="$refs['conversation-modal'].show()"/></a>-->
            <span class="nav-divider d-none d-sm-block"></span>
<!--            <a href="#" data-toggle="tooltip" title="Archive" class="nav-link d-none d-sm-block"><refresh-ccw-icon @click="loadCampaigns" style="cursor: pointer;" title="Reload conversation"/></a>-->
<!--            <a href="#" data-toggle="tooltip" title="Report Spam" class="nav-link d-none d-sm-block"><plus-square-icon style="cursor: pointer;" title="Add new conversation" @click="$refs['conversation-modal'].show()"/></a>-->
          </nav>
        </div><!-- mail-content-header -->
        <div class="mail-content-body"  style="overflow-y:scroll; height: 100%; padding: 30px;">
          <campaign-details-form v-if="screen === 'CD'"/>
          <campaign-images-form v-if="screen === 'CI'"/>
          <campaign-story-form v-if="screen === 'CS'"/>
          <SalesProjectionTable v-if="screen === 'SP'"/>
          <ExpenseProjectionTable v-if="screen === 'EP'"/>
          <CustomerProjectionTable v-if="screen === 'CP'"/>
          <ProjectTrackerTable v-if="screen === 'PT'"/>
        </div><!-- mail-content-body -->
      </div><!-- mail-content -->
    </div><!-- mail-wrapper -->
  </div>
</template>

<script>
// import BaseTopCards from "../../components/card/BaseTopCards";
// import BaseTable from "../../components/table/BaseTable";
// import BaseButton from "../../components/button/BaseButton";
import CampaignDetailsForm from "@/components/form/CampaignDetailsForm";
import CampaignImagesForm from "@/components/form/CampaignImagesForm";
import CampaignStoryForm from "@/components/form/CampaignStoryForm";
import SalesProjectionTable from "@/components/table/projection/SalesProjectionTable";
import ExpenseProjectionTable from "@/components/table/projection/ExpenseProjectionTable";
import CustomerProjectionTable from "@/components/table/projection/CustomerProjectionTable";
import ProjectTrackerTable from "@/components/table/projection/ProjectTrackerTable";
import { RefreshCcwIcon } from 'vue-feather-icons'
import {mapState} from 'vuex'

export default {
  name: 'Proprietor',
  data() {
    return {
      menu:{
        CD:[
            'Campaign Details',
            'This displays the full details of the campaign',
        ],
        CS:[
            'Campaign Story',
            'Story or full description concerning the campaign',
        ],
        CI:[
            'Campaign Images',
            'Here you can view and change the campaign images'
        ],
        SP:[
          'Sales Projection',
          'Here you can view and change the campaign Sales Projections'
        ],
        EP:[
          'Expense Projection',
          'Here you can view and change the campaign Expense Projections'
        ],
        CP:[
          'Customer Projection',
          'Here you can view and change the campaign Customer Projections'
        ],
        PT:[
          'Project Tracker',
          'Here you can view and change the campaign Project Tracker'
        ]
      },
      screen:'CD',
      activeConversation:{},
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'conversationFirstName', label: 'First Name', sortable: true, sortDirection: 'desc' },
        //{ key: 'conversationMiddleName', label: 'Middle Name', sortable: true, class: 'text-left' },
        { key: 'conversationSurname', label: 'Surname', sortable: true, class: 'text-left' },
        { key: 'conversationGender', label: 'Gender', sortable: true, sortDirection: 'desc' },
        { key: 'conversationDob', label: 'Date of Birth', sortable: true, class: 'text-left' },
        { key: 'conversationEmail', label: 'Email', sortable: true, class: 'text-left' },
        { key: 'businessName', label: 'Business Name', sortable: true, class: 'text-left' },
        { key: 'businessType', label: 'Business Type', sortable: true, class: 'text-left' },
        { key: 'businessRegDate', label: 'Business Reg Date', sortable: true, class: 'text-left' },
        { key: 'actions', label: 'Action', sortable: true, class: 'text-center' }
      ],
    }
  },
  components: {
    // BaseButton,
    CampaignDetailsForm,
    CampaignImagesForm,
    CampaignStoryForm,
    SalesProjectionTable,
    ExpenseProjectionTable,
    CustomerProjectionTable,
    ProjectTrackerTable,
    RefreshCcwIcon,
    // PlusSquareIcon,


  },
  computed: {
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total Conversations',
            number: this.departments.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'Active Conversations',
            number: this.departments.filter(it => it.employerConversationStatus.toUpperCase() === 'ACTIVE').length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Pending Conversations',
            number: this.departments.filter(it => it.employerConversationStatus.toUpperCase() === 'PENDING').length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Suspended Conversations',
            number: this.departments.filter(it => it.employerConversationStatus.toUpperCase() === 'SUSPENDED').length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    authobj: function () {
      return this.$store.state.auth.response
    },
    ...mapState([
      'campaign',
      'auth'
    ])
  },
  methods: {
    hide() {
      this.$refs['conversation-modal'].hide()
    },
    loadCampaigns(){
      this.$store.commit('campaign/updateCampaignsLoading', true)
      this.$store.dispatch('campaign/updateCampaigns', {campaignOrgId:localStorage.orgId})
    },
    selectCampaign(_id){
      this.toggleBodyClass('addClass','mail-content-show')
      this.toggleBodyClass('addClass','mail-content-show')
      this.screen = _id
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    navigate(){
      this.toggleBodyClass('removeClass','mail-content-show')
    },
    initial(_title){
      let titleArray = _title.split(' ')
      let firstInitial = titleArray[0].substring(0,1)
      let secondInitial = ""
      if (titleArray[1] != null)
        secondInitial = titleArray[1].substring(0,1)
      return firstInitial+secondInitial
    },
    randomColor(_name){
      let initial = _name.substring(0,1)
      let color = ""
      if (initial.toUpperCase() === "A") color = "bg-indigo"
      else if (initial.toUpperCase() === "B") color = "bg-light"
      else if (initial.toUpperCase() === "C") color = "bg-success"
      else if (initial.toUpperCase() === "D") color = "bg-warning"
      else if (initial.toUpperCase() === "E") color = "bg-primary"
      else if (initial.toUpperCase() === "F") color = "bg-info"
      else if (initial.toUpperCase() === "G") color = "bg-danger"
      else if (initial.toUpperCase() === "H") color = "bg-dark"
      else if (initial.toUpperCase() === "I") color = "bg-muted"
      else if (initial.toUpperCase() === "J") color = "bg-indigo"
      else if (initial.toUpperCase() === "K") color = "bg-light"
      else if (initial.toUpperCase() === "L") color = "bg-warning"
      else if (initial.toUpperCase() === "M") color = "bg-info"
      else if (initial.toUpperCase() === "N") color = "bg-light"
      else if (initial.toUpperCase() === "O") color = "bg-danger"
      else if (initial.toUpperCase() === "P") color = "bg-warning"
      else if (initial.toUpperCase() === "Q") color = "bg-info"
      else if (initial.toUpperCase() === "R") color = "bg-muted"
      else if (initial.toUpperCase() === "S") color = "bg-primary"
      else if (initial.toUpperCase() === "T") color = "bg-dark"
      else if (initial.toUpperCase() === "U") color = "bg-light"
      else if (initial.toUpperCase() === "V") color = "bg-primary"
      else if (initial.toUpperCase() === "W") color = "bg-indigo"
      else if (initial.toUpperCase() === "X") color = "bg-muted"
      else if (initial.toUpperCase() === "Y") color = "bg-primary"
      else if (initial.toUpperCase() === "Z") color = "bg-warning"
      else color = "bg-primary"
      return color
    },
    activeClass(_id1,_id2){
      if(_id1 === _id2) return 'media'
      else return 'media unread'
    },
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  created() {

  }
}


</script>

<style scoped>
@media (min-width: 1200px){
  .mail-group {
    width: 360px;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
  }
}

/*@media (min-width: 992px){*/
/*    .mail-content {*/
/*        left: 360px;*/
/*        width: auto;*/
/*        right: 0;*/
/*        display: block;*/
/*    }*/
/*     .mail-content {*/
/*         position: absolute;*/
/*         top: 0;*/
/*         left: 200vw;*/
/*         bottom: 0;*/
/*         width: 100vw;*/
/*         display: block;*/
/*         background-color: #f5f6fa;*/
/*     }*/
/*    div {*/
/*        display: block;*/
/*    }*/
/*}*/

</style>
