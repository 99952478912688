import DocumentService from "@/service/DocumentService";
import swal from "sweetalert";

export const state = {
    screen:'passcode',
    documents:{
        data: [
        ],
        responseCode: '00',
        responseMessage:'success'
    },
    documentsLoading: false
}
export  const getter = {

}
export  const mutations = {
    updateDocuments:(state, payload) =>{
        state.documents = payload
    },
    updateDocumentsLoading:(state, payload) =>{
        state.documentsLoading = payload
    },
    updateScreen:(state, payload) =>{
        state.screen = payload
    },
}
export const actions = {
    updateDocuments: ({commit,state}, payload) => {
        if (state.documents.data.length < 1)
            commit('updateDocumentsLoading', true)
        return DocumentService.callReadDocumentsApi(payload)
            .then(response => {
                commit('updateDocumentsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    commit('updateDocuments', responseData)
                    commit('updateScreen', 'document')
                }else {
                    //swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDocumentsLoading", false)
                //alert(error)
                // throw error;
            });
    },
    uploadDocuments: ({commit}, payload) => {
        //commit('updateDocumentsLoading', true)
        return DocumentService.callUploadDocumentsApi(payload)
            .then(response => {
                commit('updateDocumentsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    //swal('Success!', responseData.responseMessage, 'success')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDocumentsLoading", false)
                //alert(error)
                // throw error;
            });
    },
    updateDocumentStatus: ({commit}, payload) => {
        //commit('updateDocumentsLoading', true)
        return DocumentService.callRemoveDocumentsApi(payload)
            .then(response => {
                commit('updateDocumentsLoading', false)
                let responseData = response.data
                if (responseData.responseCode === '00') {
                    //swal('Sucess!', responseData.responseMessage, 'sucess!')
                }else {
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                commit("updateDocumentsLoading", false)
                //alert(error)
                // throw error;
            });
    }
}