<template>
    <div class="row">
        <div class="col-md-12 mg-t-10">
            <div class="card ht-100p">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h6 class="mg-b-0">Details</h6>
                    <div class="d-flex tx-18">

<!--                        <a href="#" class="link-03 lh-0"><b-icon-arrow-repeat/></a>-->
                    </div>
                </div>
                <!--                <p v-if="company.company[0] == null" align="center"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>-->
                <Form style="padding: 30px;">
                    <b-row v-if="model != null" form>
                        <b-col md="4">
                            <a :href="model.directorPassport" target="_blank" v-if="model.directorPassport !== ''">
                                <div
                                        class="image-input mb-3"
                                        :style="{ 'background-image': `url(${model.directorPassport})` }"
                                >
                                    <span
                                            v-if="!model.directorPassport"
                                            class="placeholder"
                                    >
                                    No passport found
                                    </span>
                                </div>
                            </a>
                            <div v-else
                                 class="image-input mb-3"
                                 :style="{ 'background-image': `url(${model.directorPassport})` }"
                            >
                                <span
                                        v-if="!model.directorPassport"
                                        class="placeholder"
                                >
                                No passport found
                                </span>
                            </div>
                        </b-col>
<!--                        <b-col md="4"></b-col>-->
                        <b-col md="4">
                            <a :href="model.directorSignature" target="_blank"  v-if="model.directorPassport !== ''">
                                <div
                                        class="image-input mb-3"
                                        :style="{ 'background-image': `url(${model.directorSignature})` }"
                                >
                                    <span
                                            v-if="!model.directorSignature"
                                            class="placeholder"
                                    >
                                    No Signature found
                                    </span>
                                </div>
                            </a>
                            <div v-else
                                 class="image-input mb-3"
                                 :style="{ 'background-image': `url(${model.directorSignature})` }"
                            >
                                <span
                                        v-if="!model.directorSignature"
                                        class="placeholder"
                                >
                                No Signature found
                                </span>
                            </div>
                        </b-col>
                        <b-col md="12">
                            <h5>BIO-DATA</h5>
                        </b-col>
                        <b-col md="6">
                            <b-form-group>
                                <Label for="directorLastName">Last Name</Label>
                                <b-form-input required readonly v-model="model.directorLastname" type="text" name="bvn" id="directorLastName"
                                              placeholder="directorLastName"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group>
                                <Label for="directorFirstName">First Name</Label>
                                <b-form-input required readonly v-model="model.directorFirstname" type="text" name="directorFirstName" id="directorFirstName"
                                              placeholder="directorFirstName"/>
                            </b-form-group>
                        </b-col>

                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorGender">Gender</Label>
                                <b-form-input required readonly v-model="model.directorGender" type="text" name="bvn" id="directorGender"
                                              placeholder="directorGender"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorNationality">Nationality</Label>
                                <b-form-input required readonly v-model="model.directorNationality" type="text" name="directorNationality" id="directorNationality"
                                              placeholder="directorNationality"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorDob">Date of Birth</Label>
                                <b-form-input required readonly v-model="model.directorDob"  type="text" name="directorDob" id="directorDob"
                                              placeholder="directorDob"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorDesignation">Designation</Label>
                                <b-form-input required readonly v-model="model.directorDesignation"  type="text" name="directorDesignation" id="directorDesignation"
                                              placeholder="directorDesignation Passord"/>
                            </b-form-group>
                        </b-col>


                        <b-col md="12">
                            <h5>CONTACT</h5>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorCountry">Country</Label>
                                <b-form-input required readonly v-model="model.directorCountry" type="text" name="bvn" id="directorCountry"
                                              placeholder="directorCountry"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorState">State</Label>
                                <b-form-input required readonly v-model="model.directorState" type="text" name="directorState" id="directorState"
                                              placeholder="directorState"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorCity">City</Label>
                                <b-form-input required readonly v-model="model.directorCity"  type="text" name="directorCity" id="directorCity"
                                              placeholder="directorCity"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorStreet">Street</Label>
                                <b-form-input required readonly v-model="model.directorStreet"  type="text" name="directorStreet" id="directorStreet"
                                              placeholder="directorStreet"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group>
                                <Label for="directorPhone">Phone Number</Label>
                                <b-form-input required readonly v-model="model.directorPhone"  type="text" name="directorPhone" id="directorPhone"
                                              placeholder="directorPhone"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group>
                                <Label for="directorEmail">Email</Label>
                                <b-form-input required readonly v-model="model.directorEmail"  type="text" name="directorEmail" id="directorEmail"
                                              placeholder="directorEmail"/>
                            </b-form-group>
                        </b-col>



                        <b-col md="12">
                            <h5>IDENTIFICATION</h5>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorIdType">ID Type</Label>
                                <b-form-input required readonly v-model="model.directorIdType" type="text" name="bvn" id="directorIdType"
                                              placeholder="directorIdType"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorIdNumber">ID Number</Label>
                                <b-form-input required readonly v-model="model.directorIdNumber" type="text" name="directorIdNumber" id="directorIdNumber"
                                              placeholder="directorIdNumber"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorIdIssueDate">ID Issue Date</Label>
                                <b-form-input required readonly v-model="model.directorIdIssueDate"  type="text" name="directorIdIssueDate" id="directorIdIssueDate"
                                              placeholder="directorIdIssueDate"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <Label for="directorIdExpiryDate">ID Expiry Date</Label>
                                <b-form-input required readonly v-model="model.directorIdExpiryDate"  type="text" name="directorIdExpiryDate" id="directorIdExpiryDate"
                                              placeholder="directorIdExpiryDate"/>
                            </b-form-group>
                        </b-col>
<!--                        <b-col md="12">-->
<!--                            <b-form-group>-->
<!--                                <Label for="directorBVN">BVN Number</Label>-->
<!--                                <b-form-input required readonly v-model="model.directorBVN"  type="text" name="directorBVN" id="directorBVN"-->
<!--                                              placeholder="directorBVN"/>-->
<!--                            </b-form-group>-->
<!--                        </b-col>-->
                    </b-row>
                    <div class="mt-4 d-flex align-items-right">
                        <!-- <h5 class="mb-0">
                            Already have an account?
                            <router-link to="/" class="text-primary">Sign in</router-link>
                        </h5> -->
                        <div class="ml-auto">
                            <b-button @click="$router.go(-1)" class="btn-wide btn-danger btn-pill btn-shadow btn-hover-shine" size="lg">Back</b-button>
                        </div>
                        <span v-if="auth.userInfo.companies.data[0].directorRole === 'ADMIN'">
<!--                            <div v-if="model.directorAuthBank === 'YES'" class="mr" style="margin-right:-80%;">-->
<!--                                <b-button disabled class="btn-wide btn-success btn-pill btn-shadow btn-hover-shine" size="lg">Verified</b-button>-->
<!--                            </div>-->
<!--                            <div v-else class="mr" style="margin-right:-80%;">-->
<!--                                <b-button @click="verifyOfficer()" class="btn-wide btn-info btn-pill btn-shadow btn-hover-shine" size="lg">Verify</b-button>-->
<!--                            </div>-->
                        </span>
                    </div>
                </Form>
            </div><!-- card -->
        </div>
    </div><!-- row -->
</template>

<script>
    // import FlotChart from "../FlotChart";
    import {mapState} from 'vuex'
    export default {
        name: "TopCards",
        components:{

        },
        data(){
            return {
                directors:[],
                steps: [
                    {
                        label: 'Biodata',
                        slot: 'page1',
                    },
                    {
                        label: 'Contact',
                        slot: 'page2',
                    },
                    {
                        label: 'Identification',
                        slot: 'page3',
                    },
                    {
                        label: 'Employer',
                        slot: 'page4',
                    },
                    {
                        label: 'Confirm',
                        slot: 'page5',
                    }
                ],
            };
        },
        methods: {
            nextClicked(currentPage) {
                console.log('next clicked', currentPage)
                if (currentPage >= this.steps.length-1)
                    this.createOfficer()
                return true; //return false if you want to prevent moving to next page
            },
            backClicked(currentPage) {
                console.log('back clicked', currentPage);
                return true; //return false if you want to prevent moving to previous page
            },
            async readOfficers(){
                await this.$store.dispatch("director/updateOfficers", {
                    orgId: localStorage.orgId,
                    accID: localStorage.accID,
                    requestType: 'director'
                }, {root: true})
            },
            showAlert(title, message) {
                const options = {title: title, size: 'sm'}
                this.$dialogs.alert(message, options)
                    .then(res => {
                        console.log(res) // {ok: true|false|undefined}
                    })
            },
            directorFiltered(_item) {
                return this.director.directors.data.filter(function (el) {
                    return el.directorId == _item;
                });
            },
        },
        computed:{
            ...mapState([
                'auth',
                'director'
            ]),
            model:{
                get(){
                    return this.directorFiltered(localStorage.directorId)[0]
                },
                set(){

                }
            }
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 900px) {
        .top-card {
            width: 103%;
        }
    }
    @media only screen and (min-width: 707px) and (max-width: 900px) {
        .top-card {
            width: 104%;
        }
    }

    @media only screen and (min-width: 517px)  and (max-width: 707px) {
        .top-card {
            width: 105%;
        }
    }

    @media only screen and (min-width: 451px)  and (max-width: 517px) {
        .top-card {
            width: 106%;
        }
    }

    @media only screen  and (max-width: 451px) {
        .top-card {
            width: 107%;
        }
    }
    .h-100 {
        height: 100vh !important;
    }
    @media only screen and  (max-width: 991px) {
        .extra{
            display: none;
        }
    }
    .image-input{
        display: block;
        width: 100%;
        height: 300px;
        cursor: pointer;
        background-size: cover;
        background-position: center center;
    }

    .placeholder{
        background: #F0F0F0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        font-size: 18px;
        font-family: Helvetica,serif;
    }

    .placeholder:hover{
        background: #E0E0E0
    }

    .file-input{
        display: none;
    }
</style>