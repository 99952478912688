import Logon from '@/views/auth/Logon'
import Register from '@/views/auth/Register'
import PageNotFound from "@/views/auth/PageNotFound"
import Forgot from "@/views/auth/Forgot";
import CreateCompany from "@/views/auth/CreateCompany";
import Preloader from "@/views/auth/Preloader";
export default [
    {
        path: '/',
        name: 'Logon',
        meta: {layout: 'auth',authRequired:false},
        component: Logon,
    },
    {
        path: '/preloader',
        name: 'Preloader',
        meta: {layout: 'auth',authRequired:false},
        component: Preloader,
    },
    {
        path: '/login/:source',
        name: 'Login',
        meta: {layout: 'auth',authRequired:false},
        component: Logon,
    },
    {
        path: '/register',
        name: 'Register',
        meta: {layout: 'auth',authRequired:false},
        component: Register,
    },
    {
        path: '/forgot-password',
        name: 'Forgot',
        meta: {layout: 'auth',authRequired:false},
        component: Forgot,
    },
    {
        path: '/create-company/:source',
        name: 'CreateCompany',
        meta: {layout: 'auth',authRequired:false},
        component: CreateCompany,
    },
    {
        path: '/create-company',
        name: 'CreateCompanyN',
        meta: {layout: 'auth',authRequired:false},
        component: CreateCompany,
    },
    {
        path: '*',
        name: 'PageNotFound',
        meta: {layout: '404',authRequired:false},
        component: PageNotFound,
    }
];
