<template>
  <div class="card card-file">
    <b-form enctype="multipart/form-data">
      <b-form-group>
        <div class="dropbox">
          <span v-if="url.trim() === ''">
            <div style="width: 100%; height: 39px;"></div>
            <input multiple type="file"  class="input-file" id="files" name="file"
                   @change="uploadFile" ref="imageFile">
            <div class="card-file-thumb tx-gray-600">
              <b-icon-image class="tx-gray"/>
            </div>
            <div style="width: 100%; height: 39px;"></div>
          </span>
          <span v-else>
            <img style="width: 100%; height: 180px;" :src="url">
          </span>
        </div>
      </b-form-group>
    </b-form>
    <base-loader style="margin-top: -16px; margin-bottom: 10px;" :show="showLoader"></base-loader>
    <div class="card-body" style="margin-top: -20px;">
      <h6 :title="title"><a href="#" class="link-02">{{title}}</a></h6>
      <span  v-if="url.trim() !== ''">
          <p title="download" ><span style="float: left; cursor: pointer;"><a :href="url"><b-icon-download class="tx-success"></b-icon-download></a></span></p>
          <p title="delete" ><span style="float: right; cursor: pointer;" @click="removeDocuments()"><b-icon-trash class="tx-danger" ></b-icon-trash></span></p>
      </span>
    </div>
  </div>
</template>


<script>
import BaseLoader from "../loader/BaseLoader";
import {mapState} from 'vuex'

export default {
  name: 'Loan',
  props:['url','status','name','title','uploadType','removeType','readType','isLoading','uploadData','readData'],
  data() {
    return {
      showLoader: false,
      model:{
        campaignId:'',
        imageName: '',
        type:'',
        base64:''
      },
      file: {
        file: '',
        amount: '',
        status: false,
        bank: {},
        narration: '',
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'photos',
      },
    }
  },
  components: {
    BaseLoader,
    // BaseTable,
    // BaseTopCards,
    // PrinterIcon,
    // PlusIcon,
  },
  computed: {
    disableInput:{
      get(){
        return this.url.trim() !== ''
      },
      set(){

      }
    },
    authobj: function () {
      return this.$store.state.auth.response
    },
    ...mapState([
      'grade',
      'auth'
    ])
  },
  methods: {
    uploadFile(){
      let input = this.$refs.imageFile;
      console.log(input);
      let files = input.files;
      let size = files[0].size/1000000
      console.log(size);
      if (size > 1000) {
        const options = {title: 'Error!', size: 'sm'};
        this.$dialogs.alert('File size must not be more than 5mb, please try with a reduced file size', options)
            .then(res => {
              console.log(res) // {ok: true|false|undefined}
            });
        return
      }
      const reader = new FileReader;
      try {
        reader.onload = e => {
          this.model.base64 = e.target.result;
          this.model.campaignId = this.uploadData.campaignId
          this.model.imageName = this.uploadData.imageName
          this.model.type = this.uploadData.type
          this.createDocuments()
        };
        reader.readAsDataURL(files[0]);
        this.$emit('input', files[0]);
      } catch (e) {
        console.warn(e.message);
      }
    },

    async createDocuments(){
      this.showLoader = true
      await this.$store.dispatch(this.uploadType, this.model, {root: true})
      this.loadData()
      this.showLoader = false
    },

    async removeDocuments(){
      this.showLoader = true
      await this.$store.dispatch(this.removeType, {
        campaignId: this.uploadData.campaignId,
        imageName: this.uploadData.imageName
      }, {root: true})
      this.loadData()
      this.showLoader = false
    },

    // async readDocuments(){
    //   await this.$store.dispatch("document/updateDocuments", {
    //     orgId: localStorage.orgId,
    //     accID: localStorage.accID,
    //     readAll: "NO",
    //     requestType: 'document'
    //   }, {root: true})
    // },

    loadData(){
      this.$store.dispatch(this.readType, this.readData, {root: false}).then()
    },
    hide() {
      this.$refs['csv-upload-modal'].hide()
    },
    onSelect({name, iso2, dialCode}) {
      this.model.employeeCountryCode =  dialCode
      console.log(name, iso2, dialCode);
    },
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  created() {
    this.loadData()
  },
  watch: {
    show: function () {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.uploadData).forEach(function(key,index) {
        self.model[key] = self.uploadData[key];
      });
      this.$refs['csv-upload-modal'].show()
    }
  },
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
.h-100 {
  height: 100vh !important;
}
@media only screen and  (max-width: 991px) {
  .extra{
    display: none;
  }
}
.image-input{
  display: block;
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder{
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica,serif;
}

.placeholder:hover{
  background: #E0E0E0
}

.file-input{
  display: none;
}

.dropbox {
  z-index: 0;
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: #f5f6fa;
  color: dimgray;
  /*padding: 10px 10px;*/
  /*min-height: 200px; !* minimum height *!*/
  position: relative;
  cursor: pointer;
}

.input-file {
  z-index: 0;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #f5f6fa; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  /*padding: 50px 0;*/
}

</style>