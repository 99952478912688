<template>
  <b-modal id="sales-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="sales-create-modal">
    <div class="modal-body">
      <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
         @click="hide()">
        <span aria-hidden="true">&times;</span>
      </a>
      <div>
        <h4 v-if="model.type === 'edit'">Edit Sale Projection</h4>
        <h4 v-else>Add New Sale Projection</h4>
        <b-form @submit.prevent="submit" enctype="multipart/form-data">
          <b-form-group label="Amount">
              <b-input placeholder="Amount" type="number" step="0.01" v-model="model.saleProjectionAmount"/>
          </b-form-group>
          <b-form-group label="Cash Component">
            <b-input placeholder="Cash Component" type="number" step="0.01" v-model="model.saleProjectionCashComponent"/>
          </b-form-group>
          <b-form-group label="Projection Month">
            <b-input-group>
              <b-select v-model="model.saleProjectionYear">
                <b-select-option v-for="i in 50" v-bind:key="i" :value="currentYear()+i-1">{{currentYear()+i-1}}</b-select-option>
              </b-select>
              <b-select v-model="model.saleProjectionMonth">
                <b-select-option v-for="month in months" v-bind:key="month.id" :value="month.id">{{month.name}}</b-select-option>
              </b-select>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Type">
            <b-select required v-model="model.saleProjectionType">
              <b-select-option value="PROJECTION">Projection</b-select-option>
              <b-select-option value="ACTUAL">Actual</b-select-option>
            </b-select>
          </b-form-group>
          <b-form-group v-if="model.type === 'edit'" label="Status">
            <b-select required v-model="model.saleProjectionStatus">
              <b-select-option value="ACTIVE">Active</b-select-option>
              <b-select-option value="DELETED">Delete</b-select-option>
            </b-select>
          </b-form-group>
          <base-button title="Submit" :loading="projection.projectionLoading"/>
        </b-form>
      </div>
    </div><!-- modal-body -->
  </b-modal>
</template>
<script>
import { mapState } from "vuex"
import BaseButton from "@/components/button/BaseButton";
export default {
  props:['show','row'],
  components:{BaseButton},
  name: 'SalesModal',
  data(){
    return{
      password: '',
      model:{
        type:'',
        saleProjectionId:'',
        saleProjectionBusinessId:'',
        saleProjectionCampaignId:'',
        saleProjectionMonth:'',
        saleProjectionYear:'',
        saleProjectionAmount:'',
        saleProjectionType:'',
        saleProjectionStatus:'',
        saleProjectionCashComponent:''
      },
      months:[
        {
          id:'01',
          name:'January'
        },
        {
          id:'02',
          name:'February'
        },
        {
          id:'03',
          name:'March'
        },
        {
          id:'04',
          name:'April'
        },
        {
          id:'05',
          name:'May'
        },
        {
          id:'06',
          name:'June'
        },
        {
          id:'07',
          name:'July'
        },
        {
          id:'08',
          name:'August'
        },
        {
          id:'09',
          name:'September'
        },
        {
          id:'10',
          name:'October'
        },
        {
          id:'11',
          name:'November'
        },
        {
          id:'12',
          name:'December'
        }
      ]
    }
  },
  methods:{
    hide() {
      this.$refs['sales-create-modal'].hide()
    },
    async submit(){
      this.model.saleProjectionCampaignId = localStorage.campaignId
      this.model.saleProjectionBusinessId = localStorage.orgId
      this.model.saleProjectionMonth = `${this.model.saleProjectionYear}-${this.model.saleProjectionMonth}`
      if (this.model.type === 'edit')
        await this.$store.dispatch("projection/editSalesProjection", this.model, {root: false});
      else {
        await this.$store.dispatch("projection/createSalesProjection", this.model, {root: false});
      }
      this.hide();
      this.loadData()
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });

    },
    gradeCode(){
      return  localStorage._gradeCode
    },
    loadData(){
      this.$store.dispatch("projection/updateSalesProjection", {
        readAll:'NO',
        saleProjectionCampaignId: localStorage.campaignId,
        saleProjectionBusinessId: localStorage.orgId
      }, {root: false}).then()
    },
    hideauth: function () {
      this.password = '';
      this.$refs['sales-create-modal'].hide();
    },
    currentDate(){
      let date = new Date()
      date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
      console.log(date)
      return date
    },
    currentYear(){
      return new Date().getFullYear()
    }
  },
  mounted() {
  },
  computed:{
    ...mapState(['grade','auth','projection']),
  },
  watch: {
    show: function () {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.row).forEach(function(key,index) {
        self.model[key] = self.row[key];
      });
      this.model.saleProjectionYear = this.model.saleProjectionMonth.split('-')[0]
      this.model.saleProjectionMonth = this.model.saleProjectionMonth.split('-')[1]
      this.$refs['sales-create-modal'].show()
    }
  },
}
</script>
