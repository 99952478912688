<template>
    <div class="row">
        <div class="col-md-12 mg-t-10">
            <div class="card ht-100p">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h6 class="mg-b-0">Add New Director</h6>
                    <div class="d-flex tx-18">

<!--                        <a href="#" class="link-03 lh-0"><b-icon-arrow-repeat/></a>-->
<!--                        <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a>-->
                    </div>
                </div>
<!--                <p v-if="company.company[0] == null" align="center"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>-->
                <vue-good-wizard
                        :steps="steps"
                        class="mb-3"
                        :onNext="nextClicked"
                        :onBack="backClicked">
                    <div slot="page1">
<!--                        <p v-if="company.company[0] == null" align="center"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>-->
                        <Form>
                            <b-row form>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorFirstname">First name <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" v-model="model.directorFirstname" type="text" @input="filterOutBadChar()" name="directorFirstname" id="directorFirstname"
                                                      placeholder="First name"/>
                                    </b-form-group>
                                </b-col>
                              <b-col md="6">
                                <b-form-group>
                                  <Label for="directorLastname">Last name <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                  <input class="form-control" v-model="model.directorLastname" type="text" @input="filterOutBadChar()" name="directorLastname" id="directorLastname"
                                         placeholder="Surname"/>
                                </b-form-group>
                              </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorMaidenName">Date of Birth <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" v-model="model.directorDob" @input="filterOutBadChar()" type="date" name="directorMaidenName" id="directorMaidenName"
                                                      placeholder="Date of Birth"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorGender">Gender <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <select v-model="model.directorGender" class="form-control">
                                            <option value="" selected="selected">- Select -</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorNationality">Nationality <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <country-select class="form-control" :countryName="true" :regionName="true" v-model="model.directorNationality" :country="model.directorNationality" topCountry="NG" />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorDesignation">Designation <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <select v-model="model.directorDesignation" class="form-control">
                                            <option value="" selected="selected">- Select -</option>
                                            <option value="Director">Director</option>
                                            <option value="Signatory">Signatory</option>
                                            <option value="Director and Signatory">Director and Signatory</option>
                                        </select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorRole">Role <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <select v-model="model.directorRole" class="form-control">
                                            <option value="" selected="selected">- Select -</option>
                                            <option  value="ADMIN">Admin</option>
                                            <option  value="OPERATOR">Operator</option>
                                        </select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </Form>
                    </div>
                    <div slot="page2">
                        <Form>
                            <b-row form>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorCountry">Country <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <country-select class="form-control" :countryName="true" :regionName="true" v-model="model.directorCountry" :country="model.directorCountry" topCountry="NG" />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorState">State <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <region-select class="form-control" :regionName="true" :countryName="true" v-model="model.directorState" :country="model.directorCountry" :region="model.directorState" />
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorCity">City <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" @input="filterOutBadChar()" v-model="model.directorCity" type="text" name="directorCity" id="directorCity"
                                                      placeholder="City"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorStreet">Residential Address <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" @input="filterOutBadChar()" v-model="model.directorStreet" type="text" name="directorStreet" id="directorStreet"
                                                      placeholder="Street"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorPhone">Phone Number <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" @input="filterOutBadChar()" v-model="model.directorPhone" type="text" name="phone" id="directorPhone"
                                                      placeholder="Phone Number"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorEmail">Email <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" @input="filterOutBadChar()" v-model="model.directorEmail" type="email" name="email" id="directorEmail"
                                                      placeholder="Officer Email"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </Form>
                    </div>
                    <div slot="page3">
                        <Form>
                            <b-row form>
                                <b-col md="6">
                                    <b-form-group>
<!--                                        <b-form-group>-->
<!--                                            <Label for="directorIdNumber">Means of Identification <span style="color: red !important; display: inline; float: none;">*</span></Label>-->
<!--                                            <input class="form-control" @input="filterOutBadChar()" v-model="model.directorIdType" type="text" name="directorIdType" id="directorIdType"-->
<!--                                                          placeholder="Means of Identification"/>-->
<!--                                        </b-form-group>-->
                                      <Label for="directorIdType">Means of Identification <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                      <select id="directorIdType" v-model="model.directorIdType" class="form-control">
                                          <option value="" selected="selected">- Select -</option>
                                          <option v-for="idType in director.directorIdTypes.data" :value="idType.value" v-bind:key="idType.value">
                                            {{ idType.label }}</option>
                                      </select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorIdNumber">ID Number <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" @input="filterOutBadChar()" v-model="model.directorIdNumber" type="text" name="directorIdNumber" id="directorIdNumber"
                                                      placeholder="ID Number"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorCity">Issue Date <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" @input="filterOutBadChar()" v-model="model.directorIdIssueDate" type="date" name="directorIdIssueDate" id="directorIdIssueDate"
                                                      placeholder="Issue Date"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group>
                                        <Label for="directorStreet">Expiry Date <span style="color: red !important; display: inline; float: none;">*</span></Label>
                                        <input class="form-control" @input="filterOutBadChar()" v-model="model.directorIdExpiryDate" type="date" name="directorIdExpiryDate" id="directorIdExpiryDate"
                                                      placeholder="Expiry Date"/>
                                    </b-form-group>
                                </b-col>
<!--                                <b-col md="12">-->
<!--                                    <b-form-group>-->
<!--                                        <Label for="directorBvn">BVN <span style="color: red !important; display: inline; float: none;">*</span></Label>-->
<!--                                        <input class="form-control" @input="filterOutBadChar()" v-model="model.directorBvn" type="text" maxlength="11" name="BVN" id="directorBvn"-->
<!--                                                      placeholder="BVN"/>-->
<!--                                    </b-form-group>-->
<!--                                </b-col>-->
                            </b-row>
                        </Form>
                    </div>
                    <div slot="page4">
                        <strong>Please, confirm the information provided before submission.</strong>
                        <button @click="exportPDF()"  class="btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-l-5">
                            <span ref="directorPdf"> <b-icon-document-text/> Download as PDF</span>
                        </button>
                        <br><br>
                        <div id="directorPdf">
                            <table class="table" style="width: 70%;" ref="directorPdf">
                                <tr>
                                    <th  class="font-weight-bolder" style="text-align: center; font-size: 14px;  width: 12%;">Biodata</th>
                                    <th><p style="color: white;">-</p></th>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px; width: 12%;">Surname:</td>
                                    <td style="font-size: 14px; width: 12%; color: red;" v-if="model.directorLastname === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px; width: 12%;" v-else> {{model.directorLastname}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">First Name:</td>
                                    <td style="font-size: 14px;  color: red;" v-if="model.directorFirstname === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorFirstname}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Gender:</td>
                                    <td style="font-size: 14px;  color: red;" v-if="model.directorGender === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorGender}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Date of Birth:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorDob === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorDob}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Nationality:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorNationality === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorNationality}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Designation:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorDesignation === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorDesignation}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Role:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorRole === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorRole}}</td>
                                </tr>
                                <tr>
                                    <th class="font-weight-bolder" style="text-align: center; font-size: 14px;">Contact</th>
                                    <th><p style="color: white;">-</p></th>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Country:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorCountry === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorCountry}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">State:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorState === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorState}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">City:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorCity === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorCity}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Residential Address:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorStreet === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorStreet}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Phone Number:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorPhone === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorPhone}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Email:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorEmail === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else>
                                        <p v-if="model.directorEmail.match(/^[\w-\.! # $ % & ' * + - / = ? ^ _ ` { |]+@([\w-]+\.)+[\w-]{2,}$/g).length > 0">{{model.directorEmail}}</p>
                                        <p v-else style="font-size: 14px; color: red;">Invalid Email Address</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="font-weight-bolder" style="text-align: center; font-size: 14px;">Indentification</th>
                                    <th><p style="color: white;">-</p></th>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Means of Identification:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorIdType === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorIdType}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">ID Number:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorIdNumber === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorIdNumber}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Issue Date:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorIdIssueDate === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorIdIssueDate}}</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 14px;">Expiry Date:</td>
                                    <td style="font-size: 14px; color: red;" v-if="model.directorIdExpiryDate === ''" > This field is mandatory</td>
                                    <td style="font-size: 14px;" v-else> {{model.directorIdExpiryDate}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </vue-good-wizard>
                <template>
                    <loading :active.sync="director.directorsLoading"
                             :can-cancel="false"
                             color="#6d0606"
                             :is-full-page="fullPage"></loading>
                </template>
            </div><!-- card -->
        </div>
    </div><!-- row -->
</template>

<script>
    // import FlotChart from "../FlotChart";
    // Import component
    import Loading from 'vue-loading-overlay';
    import jsPdf from'jspdf'
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {mapState} from 'vuex'
    import swal from "sweetalert";
    export default {
      name: "TopCards",
      components:{
          Loading
      },
      data(){
          return {
              directors:[],
              isLoading: true,
              fullPage: true,
              model: {
                  directorDob: '',
                  directorLastname: '',
                  directorFirstname: '',
                  directorMiddlename: '',
                  directorMaidenName:'',
                  directorGender:'',
                  directorNationality:'',
                  directorDesignation:'',
                  directorTransactionLimit:'',
                  directorCountry:'',
                  directorCountryCode:'234',
                  directorState:'',
                  directorCity:'',
                  directorStreet:'',
                  directorPhone:'',
                  directorEmail:'',
                  directorIdType:'',
                  directorIdNumber:'',
                  directorIdIssueDate:'',
                  directorIdExpiryDate:'',
                  directorBvn:'',
                  directorEmpName:'',
                  directorOccupation:'',
                  directorIndustry:'',
                  directorEmpPhone:'',
                  directorEmpAddress:'',
                  directorNearestLandmark:'',
                  directorResidentPermitNo:'',
                  directorClassOfSignatory:'',
                  directorOtherNumber:'',
                  directorRole:'',
                  requestType:'director',
                  subscribe: true
              },
              steps: [
                  {
                      label: 'Biodata',
                      slot: 'page1',
                  },
                  {
                      label: 'Contact',
                      slot: 'page2',
                  },
                  {
                      label: 'Identification',
                      slot: 'page3',
                  },
                  // {
                  //     label: 'Employer',
                  //     slot: 'page4',
                  // },
                  {
                      label: 'Confirm',
                      slot: 'page4',
                  }
              ],
          };
      },
      methods: {
          nextClicked(currentPage) {
              console.log('next clicked', currentPage)
              if (currentPage >= this.steps.length-1)
                  this.createOfficer()
              return true; //return false if you want to prevent moving to next page
          },
          backClicked(currentPage) {
              console.log('back clicked', currentPage);
              return true; //return false if you want to prevent moving to previous page
          },
          filterOutBadChar(){
              this.model.directorFirstname = this.model.directorFirstname.replace(/[^A-Z]/i, '')
              this.model.directorLastname = this.model.directorLastname.replace(/[^A-Z\s]/i, '')
              this.model.directorGender = this.model.directorGender.replace(/[^A-Z0-9]/i, '')
              this.model.directorNationality = this.model.directorNationality.replace(/[^A-Z0-9]/i, '')
              this.model.directorCity = this.model.directorCity.replace(/[^A-Z\s]/i, '')
              //this.model.directorStreet = this.model.directorStreet.replace(/[^A-Z0-9]/i, '')
              this.model.directorPhone = this.model.directorPhone.replace(/[^0-9]/i, '')
              // this.model.directorOtherNumber = this.model.directorOtherNumber.replace(/[^0-9]/i, '')
              this.model.directorIdType = this.model.directorIdType.replace(/[^A-Z0-9\s]/i, '')
              this.model.directorIdNumber = this.model.directorIdNumber.replace(/[^A-Z0-9]/i, '')
              // this.model.directorEmpName = this.model.directorEmpName.replace(/[^A-Z0-9\s]/i, '')
              // this.model.directorEmpPhone = this.model.directorEmpPhone.replace(/[^0-9]/i, '')
              // this.model.directorOccupation = this.model.directorOccupation.replace(/[^A-Z\s]/i, '')
          },
          async readOfficers(){
              await this.$store.dispatch("director/updateOfficers", {
                  orgId: localStorage.orgId,
                  accId: localStorage.accID,
                  requestType: 'director'
              }, {root: true})
          },
          showAlert(title, message) {
              const options = {title: title, size: 'sm'}
              this.$dialogs.alert(message, options)
                  .then(res => {
                      console.log(res) // {ok: true|false|undefined}
                  })
          },
          async createOfficer(){
              if (this.model.directorLastname !== ''
                  && this.model.directorFirstname !== ''
                  && this.model.directorGender !== ''
                  && this.model.directorNationality !== ''
                  && this.model.directorDesignation !== ''
                  && this.model.directorCountry !== ''
                  && this.model.directorState !== ''
                  && this.model.directorCity !== ''
                  && this.model.directorStreet !== ''
                  && this.model.directorPhone !== ''
                  && this.model.directorEmail !== ''
                  && this.model.directorIdType !== ''
                  && this.model.directorIdNumber !== ''
                  && this.model.directorIdIssueDate !== ''
                  && this.model.directorIdExpiryDate !== ''
                  && this.model.directorRole !== ''
              ) {
                  if (this.model.directorEmail.match(/^[\w-\\.! # $ % & ' * + - / = ? ^ _ ` { |]+@([\w-]+\.)+[\w-]{2,}$/g).length > 0){
                      this.isLoading = true;
                      this.model.directorCreatedBy = this.auth.userInfo.id;
                      this.model.directorAddedBy = this.auth.userInfo.id;
                      this.model.directorAddedByFirstName = this.auth.userInfo.firstName;
                      this.model.directorAddedByLastName = this.auth.userInfo.lastName;
                      this.model.directorBusinessId = localStorage.orgId;
                      this.model.directorOrgName = this.auth.userInfo.companies.data[0].orgName;
                    await this.$store.dispatch("director/createDirector", this.model, {root: true})
                  }else {
                      swal('Error!','Invalid email address!','error')
                  }

              }else{
                  // eslint-disable-next-line no-unused-vars
                  swal('Error!','Empty field detected. Please fill fields with the (*)','error').then();
              }
          },
          exportPDF() {
              let doc = new jsPdf('p', 'pt', 'a4');
              //A4 - 595x842 pts\

              //Html source
              let source = document.getElementById('directorPdf').innerHTML;

              let specialElementHandlers = {
                  // element with id of "bypass" - jQuery style selector
                  // eslint-disable-next-line no-unused-vars
                  '.no-export': function(element, renderer) {
                      // true = "handled elsewhere, bypass text extraction"
                      return true;
                  }
              };


              let margins = {
                  top: 10,
                  bottom: 10,
                  left: 10,
                  width: 595
              };

              doc.fromHTML(
                  source, // HTML string or DOM elem ref.
                  margins.left,
                  margins.top, {
                      'width': margins.width,
                      'elementHandlers': specialElementHandlers
                  },

                  // eslint-disable-next-line no-unused-vars
                  function(dispose) {
                      // dispose: object with X, Y of the last line add to the PDF
                      //          this allow the insertion of new lines after html
                      doc.save('director.pdf');
                  }, margins);
          }

  },
      computed:{
          ...mapState([
              'auth',
              'company',
              'director'
          ])
      },
      mounted() {
        this.$store.dispatch('director/updateDirectorIdTypes',{readAll:'YES'})
      }
    }
</script>

<style scoped>
    @media only screen and (min-width: 900px) {
        .top-card {
            width: 103%;
        }
    }
    @media only screen and (min-width: 707px) and (max-width: 900px) {
        .top-card {
            width: 104%;
        }
    }

    @media only screen and (min-width: 517px)  and (max-width: 707px) {
        .top-card {
            width: 105%;
        }
    }

    @media only screen and (min-width: 451px)  and (max-width: 517px) {
        .top-card {
            width: 106%;
        }
    }

    @media only screen  and (max-width: 451px) {
        .top-card {
            width: 107%;
        }
    }
</style>